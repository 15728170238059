import React, { useEffect, useState } from "react";
import IntroBanner from "../components/IntroBanner";
import { Box, Divider, Link, Typography, useMediaQuery } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getJobPost } from "../utils/admin";
import Loader from "../components/Loader";

function Career() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");
  const [isLoading, setisLoading] = useState(true)
  // const careerList = [
  //   {
  //     id: "1",
  //     title: "job1",
  //     experience: "1", //in months / year option to be provided
  //     location: "delhi",
  //     description:
  //       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut quasi voluptatum repellat laboriosam, quam maiores debitis porro perspiciatis reprehenderit, excepturi nesciunt, aliquid tenetur. Hic consequatur quasi natus, nostrum laborum quo!",
  //     isDisabled: true,
  //   },
  //   {
  //     id: "2",
  //     title: "job2",
  //     experience: "8", //in months / year option to be provided
  //     location: "lucknow",
  //     description:
  //       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut quasi voluptatum repellat laboriosam, quam maiores debitis porro perspiciatis reprehenderit, excepturi nesciunt, aliquid tenetur. Hic consequatur quasi natus, nostrum laborum quo!",
  //     isDisabled: true,
  //   },
  //   {
  //     id: "3",
  //     title: "job3",
  //     experience: "6", //in months / year option to be provided
  //     location: "mohali",
  //     description:
  //       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut quasi voluptatum repellat laboriosam, quam maiores debitis porro perspiciatis reprehenderit, excepturi nesciunt, aliquid tenetur. Hic consequatur quasi natus, nostrum laborum quo!",
  //     isDisabled: true,
  //   },
  //   {
  //     id: "4",
  //     title: "job4",
  //     experience: "3", //in months / year option to be provided
  //     location: "noida",
  //     description:
  //       "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut quasi voluptatum repellat laboriosam, quam maiores debitis porro perspiciatis reprehenderit, excepturi nesciunt, aliquid tenetur. Hic consequatur quasi natus, nostrum laborum quo!",
  //     isDisabled: true,
  //   },
  // ];
  const [careerListData, setCareerListData] = useState()


  useEffect(() => {
    async function fetchJobPosts() {
      try {
        const jobDetailResponse = await getJobPost()
        if (jobDetailResponse && jobDetailResponse.length) {
          setCareerListData(jobDetailResponse)
        }
        setisLoading(false)

      } catch (error) {
        setCareerListData([])
        // console.log(error);
        setisLoading(false)
      }
    }
    fetchJobPosts()
  }, [])
  if(isLoading){
    return <Loader />
  }
  return (
    <>
      <IntroBanner>
        <Typography
          sx={{
            color: "#004DD8",
            letterSpacing: "0px",
            paddingBottom: "40px",
          }}
          variant="h3"
        >
          {"Career"}
        </Typography>
        <Divider
          variant="fullWidth"
          sx={{ border: "2px solid #FF6525", width: "96px" }}
        />
        <Typography
          sx={{ paddingTop: "22px", width: isMobile ? '300px' : isTablet? '450px' :"600px", textAlign: "center" }}
        >
          {
            "The NewHatch team offers to help organisations plan, design and implement technology solutions. Some of the most common technology solutions. Some of the most common technology !"
          }
        </Typography>
      </IntroBanner>

      <Box
        sx={{
          backgroundColor: "#F9FAFF",
          marginTop: "40px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: isMobile
              ? "1fr"
              : isTablet
                ? "1fr 1fr"
                : "1fr 1fr 1fr",

            gap: isMobile ? "40px" : isTablet ? "30px" : "40px",
            height: "100%",
            width: "100%",
            maxWidth: "1200px",
            paddingTop: "60px",
            paddingBottom: "80px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {careerListData?.length ? careerListData
            // .filter((ele) => ele.isDisabled)
            .map((ele) => (
              <Box
                sx={{
                  width: "280px",
                  height: "300px",
                  background: "transparent white 0% 0% no-repeat padding-box",
                  boxShadow: "0px 7px 23px #0000001C",
                  padding: "40px 40px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    paddingBottom: "15px",
                    width: isTablet || isMobile ? "100%" : "150px",
                    wordBreak: "break-word",
                    color: "#000000",
                    fontSize: "18px",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {ele.title}
                </Typography>
                <Divider
                  variant="fullWidth"
                  sx={{ border: "2px solid #FF6525" }}
                />
                <Typography
                  sx={{
                    paddingTop: "15px",
                    width: isTablet || isMobile ? "100%" : "300px",
                    color: "#919191",
                    textOverflow: 'ellipsis',
                    maxHeight: '100px',
                    overflow: 'hidden',
                  }}
                >
                  {ele.description}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: "15px",
                  }}
                >
                  <Link href={`/career/${ele.id}`}>
                    <ArrowForwardIosIcon />
                  </Link>
                </Box>
              </Box>
            )): <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',width:'100%'}}>
              <Typography sx={{textAlign:'center', fontWeight:'600', width:'100%' }}>
                {'No job openings for now.'}
              </Typography>
              </Box>}
        </Box>
      </Box>
    </>
  );
}

export default Career;
