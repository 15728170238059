import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const AdminFooter = () => {
  const styles ={
    footer: {
      marginTop: 'auto',
      padding: '15px 0px',
      display:'flex',
      justifyContent:'center',
      background: '#485579',
      textAlign: 'center',
      color:'white',
    }
  }
  return (
    <Box sx={styles.footer}>
    <Container maxWidth="sm">
      <Typography variant="body2" color="textSecondary" sx={{color:'white'}}>
        &copy; {new Date().getFullYear()} newHatch. All Rights Reserved.
      </Typography>
    </Container>
  </Box>
  )
}

export default AdminFooter