// import { createClient } from '@google/maps/lib';
import React from 'react'

function Map() {

    // const googleMaps = createClient({
    //     key: "YOUR_API_KEY"
    //   })

      // useEffect(() => {
      //   // initMap()

      // })
      
    // function initMap() {
    //     // Create a new StyledMapType object, passing it an array of styles,
    //     // and the name to be displayed on the map type control.
    //     const styledMapType = new googleMaps.maps.StyledMapType(
    //       [
    //         { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
    //         { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
    //         { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
    //         {
    //           featureType: "administrative",
    //           elementType: "geometry.stroke",
    //           stylers: [{ color: "#c9b2a6" }],
    //         },
    //         {
    //           featureType: "administrative.land_parcel",
    //           elementType: "geometry.stroke",
    //           stylers: [{ color: "#dcd2be" }],
    //         },
    //         {
    //           featureType: "administrative.land_parcel",
    //           elementType: "labels.text.fill",
    //           stylers: [{ color: "#ae9e90" }],
    //         },
    //         {
    //           featureType: "landscape.natural",
    //           elementType: "geometry",
    //           stylers: [{ color: "#dfd2ae" }],
    //         },
    //         {
    //           featureType: "poi",
    //           elementType: "geometry",
    //           stylers: [{ color: "#dfd2ae" }],
    //         },
    //         {
    //           featureType: "poi",
    //           elementType: "labels.text.fill",
    //           stylers: [{ color: "#93817c" }],
    //         },
    //         {
    //           featureType: "poi.park",
    //           elementType: "geometry.fill",
    //           stylers: [{ color: "#a5b076" }],
    //         },
    //         {
    //           featureType: "poi.park",
    //           elementType: "labels.text.fill",
    //           stylers: [{ color: "#447530" }],
    //         },
    //         {
    //           featureType: "road",
    //           elementType: "geometry",
    //           stylers: [{ color: "#f5f1e6" }],
    //         },
    //         {
    //           featureType: "road.arterial",
    //           elementType: "geometry",
    //           stylers: [{ color: "#fdfcf8" }],
    //         },
    //         {
    //           featureType: "road.highway",
    //           elementType: "geometry",
    //           stylers: [{ color: "#f8c967" }],
    //         },
    //         {
    //           featureType: "road.highway",
    //           elementType: "geometry.stroke",
    //           stylers: [{ color: "#e9bc62" }],
    //         },
    //         {
    //           featureType: "road.highway.controlled_access",
    //           elementType: "geometry",
    //           stylers: [{ color: "#e98d58" }],
    //         },
    //         {
    //           featureType: "road.highway.controlled_access",
    //           elementType: "geometry.stroke",
    //           stylers: [{ color: "#db8555" }],
    //         },
    //         {
    //           featureType: "road.local",
    //           elementType: "labels.text.fill",
    //           stylers: [{ color: "#806b63" }],
    //         },
    //         {
    //           featureType: "transit.line",
    //           elementType: "geometry",
    //           stylers: [{ color: "#dfd2ae" }],
    //         },
    //         {
    //           featureType: "transit.line",
    //           elementType: "labels.text.fill",
    //           stylers: [{ color: "#8f7d77" }],
    //         },
    //         {
    //           featureType: "transit.line",
    //           elementType: "labels.text.stroke",
    //           stylers: [{ color: "#ebe3cd" }],
    //         },
    //         {
    //           featureType: "transit.station",
    //           elementType: "geometry",
    //           stylers: [{ color: "#dfd2ae" }],
    //         },
    //         {
    //           featureType: "water",
    //           elementType: "geometry.fill",
    //           stylers: [{ color: "#b9d3c2" }],
    //         },
    //         {
    //           featureType: "water",
    //           elementType: "labels.text.fill",
    //           stylers: [{ color: "#92998d" }],
    //         },
    //       ],
    //       { name: "Styled Map" }
    //     );
    //     // Create a map object, and include the MapTypeId to add
    //     // to the map type control.
    //     const map = new googleMaps.maps.Map(document.getElementById("map"), {
    //       center: { lat: 55.647, lng: 37.581 },
    //       zoom: 11,
    //       mapTypeControlOptions: {
    //         mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain", "styled_map"],
    //       },
    //     });
      
    //     //Associate the styled map with the MapTypeId and set it to display.
    //     map.mapTypes.set("styled_map", styledMapType);
    //     map.setMapTypeId("styled_map");
    //   }
    
  return (
    <div id="map" />
  )
}

export default Map