import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getJobPost } from '../utils/admin';
import IntroBanner from '../components/IntroBanner'
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import ModalFormStepper from '../components/ModalFormStepper';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import Loader from '../components/Loader';
import { numberOfDaysPast, removeStyleAttributes } from '../utils/common';

const JobDescription = () => {
    const [jobDetails, setjobDetails] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [totalApplicant, settotalApplicant] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const { slug } = useParams();
    const Navigate =  useNavigate();
    // console.log(slug)

    const getTotalApplicant = async (id) => {
        const queryGetContact = id ? query(collection(db, "contact"), where("jobEnquiryId", "==", `${id}`)) : '';
        let docRef = queryGetContact ? await getDocs(queryGetContact) : '';
        const totalApplicants = docRef && docRef?.docs?.length ? docRef.docs.map(doc => doc.id).length : 0;
        return totalApplicants;
    }

    useEffect(() => {
        async function fetchJobPosts() {
            try {
                if (slug) {
                    const jobDetailResponse = await getJobPost(`${slug}`)
                 
                    if (jobDetailResponse && jobDetailResponse.length) {
                       
                            setjobDetails(jobDetailResponse[0])
                        
                        const totalApplicant = await getTotalApplicant(jobDetailResponse[0].id);
                        settotalApplicant(totalApplicant)
                    }
                    setIsLoading(false)
                } else {
                    Navigate('/career')
                }
            } catch (error) {
                // console.log(error);
                setIsLoading(false)
            }
        }
        fetchJobPosts()
    }, [Navigate, slug])

    
    if (isLoading) {
        return<Box> <Loader /></Box>
    }


    return (
        <>

            <IntroBanner>
                <Typography
                    sx={{
                        color: "#004DD8",
                        letterSpacing: "0px",
                        paddingBottom: "40px",
                    }}
                    variant="h3"
                >
                    {"Career"}
                </Typography>
                <Divider
                    variant="fullWidth"
                    sx={{ border: "2px solid #FF6525", width: "96px" }}
                />
                <Typography
                    sx={{ paddingTop: "22px", width: "600px", textAlign: "center", }}
                >
                    {
                        "The NewHatch team offers to help organisations plan, design and implement technology solutions. Some of the most common technology solutions. Some of the most common technology !"
                    }
                </Typography>
            </IntroBanner>

            <Box sx={{ backgroundColor: '#F9FAFF', marginTop: "40px", display: 'flex', justifyContent: 'center', width: '100%', height: '100%', padding: "40px 0px" }}>
                <Container>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: "auto", background: '#fff', padding: '30px', borderRadius: '10px', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', maxHeight: '200px', width: '100%', height: "auto", justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography
                                        sx={{
                                            color: "black",
                                            letterSpacing: "2px",
                                            paddingBottom: "10px",
                                            textTransform: 'capitalize',
                                            fontWeight: 'bold',
                                            fontSize: '20px'
                                        }}
                                        variant="h5"
                                    >
                                        {jobDetails?.title ? jobDetails?.title : ''}
                                    </Typography>
                                    <Divider
                                        variant="fullWidth"
                                        sx={{ border: "2px solid #FF6525", width: "96px" }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', columnGap: '20px' }}>
                                    <Typography
                                        sx={{
                                            color: "#004DD8",
                                            letterSpacing: "0px",
                                        }}
                                        variant="h8"
                                    >
                                        {`Expeirence: ${jobDetails?.experience ? jobDetails?.experience : ''}`}
                                    </Typography>
                                    <Box
                                        variant="fullWidth"
                                        sx={{ borderRight: "3px solid #FF6525", width: "0px", height: '20px' }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#004DD8",
                                            letterSpacing: "0px",
                                            textTransform: 'capitalize'
                                        }}
                                        variant="h8"
                                    >
                                        {`Location: ${jobDetails?.location ? jobDetails?.location.toLowerCase() : ''}`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Button
                                variant='primary'
                                sx={{
                                    background: 'linear-gradient(60deg,#0552D8, #3DB8EA)',
                                    padding: "15px 60px",
                                    height: "max-content",
                                    borderRadius: '12px'
                                }}
                                onClick={() => setIsOpen(true)}
                            >
                                <Typography
                                    sx={{
                                        color: "white",
                                        letterSpacing: "0px",
                                        textTransform: "capitalize",
                                        fontWeight: '500',
                                    }}
                                    variant="h5"
                                >{'Apply'}
                                </Typography>
                            </Button>
                        </Box>

                        <Divider
                            variant="fullWidth"
                            sx={{ border: "2px solid #F1F1F1", width: "100%" }}
                        />

                        {/* APPLICANT INFO AND CREATED/UPDATEDAT */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 'max-content', columnGap: '20px', margin: '20px 0px' }}>
                            <Typography
                                sx={{
                                    color: "#939393",
                                    letterSpacing: "0px",
                                }}
                                variant="h8"
                            >
                                {`Posted: ${jobDetails?.updatedAt ? numberOfDaysPast(jobDetails?.updatedAt) : '4'} days ago`}
                            </Typography>
                            <Box
                                variant="fullWidth"
                                sx={{ borderRight: "1px solid #939393", width: "0px", height: '20px' }}
                            />

                            <Typography
                                sx={{
                                    color: "#939393",
                                    letterSpacing: "0px",
                                    textTransform: 'capitalize'
                                }}
                                variant="h8"
                            >
                                {`Job Applicants: ${totalApplicant ? totalApplicant : 0}`}
                            </Typography>

                        </Box>

                    </Box>



                    <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '200px', width: '100%', height: "auto", padding: '30px', borderRadius: '10px', justifyContent: 'space-between', marginBottom:'60px' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>{`Job Description`}</Typography>
                        {
                            jobDetails?.jobDescription ? jobDetails.jobDescription.map((ele) => (
                                <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '200px', width: '100%', height: "auto", padding: '30px 0px' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>{ele.title}</Typography>
                                    <Box><div style={{'span':{marginBottom:'30px'}}} dangerouslySetInnerHTML={{ __html: removeStyleAttributes(ele.content) }}></div></Box>
                                </Box>)) : <></>
                        }
                    </Box>
                </Container>
            </Box>
            {jobDetails?.id ? <ModalFormStepper open={isOpen} handleClose={() => setIsOpen(false)} jobDetails={jobDetails} /> : <></>}
        </>
    )
}

export default JobDescription
