import { v4 as uuidv4 } from 'uuid'
import { writeText } from 'clipboard-polyfill';

export const generateUUID = () => {
    return uuidv4(); // Generate a random UUID
};

export const copyToClipboard = (text) => {
    writeText(text)
        .then(() => {
            // Successful copy
            console.log('Text copied to clipboard:', text);
        })
        .catch((error) => {
            console.error('Failed to copy text:', error);
        });
}

export const numberOfDaysPast = (dateString) => {
    if (!dateString) return;


    const updatedAtDate = typeof dateString === 'string' ? new Date(dateString) : new Date(
        dateString._seconds * 1000 + dateString._nanoseconds / 1e6
    );

    // Calculate the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const timeDifference = currentDate - updatedAtDate;

    // Calculate the number of days by dividing the time difference by the number of milliseconds in a day
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
}

export const removeStyleAttributes = (htmlString) => {
    return htmlString.replace(/style="[^"]*"/g, '');
}

export const capitalizeFirstLetter = (inputString) => {

    let updatedString = inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();

    // Find all occurrences of text within parentheses
    let matches = updatedString.match(/\(([^)]+)\)/g);

    // If there are matches, capitalize the text within parentheses
    if (matches) {
        matches.forEach(match => {
            let textWithinParentheses = match.substring(1, match.length - 1);
            let capitalizedText = textWithinParentheses.toUpperCase();
            updatedString = updatedString.replace(textWithinParentheses, capitalizedText);
        });
    }

    return updatedString
}