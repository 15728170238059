const ABOUT_US_PAGE_CONTENT = {
  ourProcess: [
    {
      title: "Introductory Meeting",
      description:
        "We believe introductory meetings should be more than a few notes and a couple questions, they should be an immersive experience filled with valuable information that can help us deliver on projects. We dive deep into your",
    },
    {
      title: "Brainstorm Session",
      description:
        "Next, we’ll sit down to ideate the best way to build out your project scope. From how many consultants you need to their skillsets to the duration of the project, we’ll create a plan of action to elevate your operation. So, sit back, relax, and let us do the heavy lifting.",
    },
    {
      title: "Presentation",
      description:
        "We want to take the weight from your shoulders, but that doesn’t mean you don’t play a huge role in how we work. Once we have our scope and plan built out, we’ll present everything to you, giving us the chance to work together to finalize each step. If you decide you need more people, we’ll make sure you’re covered. If you need to review cost, we can work it out—whatever your needs, we’ll come to a conclusion that makes you feel confident",
    },
    {
      title: "Delivery",
      description:
        "With our national network of experienced contractors, as well as the use of our Workforce Transformation programs, Elevate and Project Patriot, we’ll build you the teams you need from some of the best talent out there—all in scope, budget, and expertise.",
    },
    {
      title: "Monitor & Manage",
      description:
        "Once we place consultants within your teams, we’ll be there to ensure everything is going as planned. We’ll help manage things so you don’t have to, monitor project progress, and keep budgets on point.",
    },
    {
      title: "Continued Support",
      description:
        "We’re your go-to for consultant support, additional resources, and fixing any issues that you may have with our placements. We’ll never leave you high and dry and the phrase “tough luck” isn’t in our vocabulary. We’ll be here for you for as long as you need. That’s a promise.",
    },
  ],
  ourApproach: [
    {
      title: "Introductory Meeting",
      description: `Talk to a recruiter about where you want your career to go.
      We’ll set up our first face-to-face or Skype call to introduce you to the account team you’ll be working with.`,
    },
    {
      title: "Resume Building",
      description: `We’ll help customize your resume for the client we think will be the best fit.
      Resume Feedback Once we get feedback from them, we’ll connect with you to provide helpful advice on how we can develop your resume.`,
    },
    {
      title: "Training",
      description: `From there, we’ll coach you through interview preparation, ensuring you feel confident presenting yourself and your skills.`,
    },
    {
      title: "Interview Preparation",
      description: `Before you interview our client, we’ll meet you 30 minutes before for a trial run to make sure you’re ready. From there, we’ll walk you in and introduce you to the hiring manager and interview team before letting you take the wheel.`,
    },
    {
      title: "Interview Feedback",
      description:
        "We’re all about helping you improve and succeed, so we’ll go through feedback from your interview with you to highlight your strong points and areas that need improvement.",
    },
    {
      title: "On-Boarding",
      description:
        "Once we receive an offer, we’ll go through it together, hammer out the details, and get to work onboarding you to your new team.",
    },
    {
      title: "Support",
      description: `That’s it—you can take it from here. Oh, but we will be here throughout the duration of your project, checking in, delivering checks, and staying on top of how things are going. Because we don’t just want to find you a job, we want to be a partner in building your career.`,
    },
  ],
  whyPartnerUs: [
    "Comprehensive service portfolio to supplement your services and enhance your customer experience.",
    "Proven Marketing and Sales support to drive business.",
    "Staffing and training top talent to ensure continued success.",
    "Stellar service delivery processes to maximize efficiency.",
  ],
  ourMissions: [
    "Providing high-quality technology consulting services that help organizations make informed technology decisions and improve their IT systems and processes.",
    "Delivering innovative and practical technology solutions that support the unique needs and requirements of each client.",
    "Building long-term relationships with clients based on trust, transparency, and mutual success.",
    "Continuously improving and updating its consulting services to stay at the forefront of technology and meet the evolving needs of clients.",
    "Providing a supportive and rewarding work environment for its employees and fostering a culture of innovation and collaboration.",
  ],
};

const SERVICES_PAGE_CONTENT = {
  ourServices: [
    {
      title: "TECHNOLOGY CONSULTING",
      desciption:
        "The Newhatch team offers to help organisations plan, design, and implement technology solutions. Some of the most common technology consulting services available are:",
      list: [
        "Expertise and experience: IT consultants bring a wealth of experience and knowledge to organisations, helping them identify and solve complex technology-related problems.",
        `Fresh perspective: An outside consultant can provide a fresh perspective on a company's technology-related challenges, helping to identify new solutions and approaches.`,
        `Access to cutting-edge technology: IT consultants often have access to the latest technology and tools, which they can leverage to help organisations keep pace with the rapidly changing tech landscape.`,
        `Cost savings: IT consultants can help organisations avoid costly technology mistakes and reduce long-term IT expenses.`,
        `Improved efficiency: By streamlining and optimising technology systems, IT consultants can help organisations improve efficiency and productivity, freeing up resources for other important tasks.`,
        `Risk management: IT consultants can help organisations identify and mitigate potential technology risks, protecting their critical data and systems from harm.`,
      ],
      sx: {
        background: "#FFFCF9 0% 0% no-repeat padding-box",
        border: "1px solid #FFDDBB",
      },
      id: "TECHNOLOGYCONSULTING",
    },
    {
      title: "SOFTWARE DEVELOPMENT",
      desciption:
        "Our developers offer to design, develop, and maintain software applications. Some of the most common software development services available are:",
      list: [
        `Web development : designing and developing websites and web applications.`,
        `Mobile app development : designing and developing applications for mobile devices, such as smartphones and tablets.`,
        `Custom software development : designing and developing software applications tailored to specific business needs and requirements.`,
        `Cloud application development : designing and developing cloud-based applications that can be accessed over the internet.`,
        `E-commerce development : designing and developing online stores and platforms for buying and selling products and services.`,
        `Game development : designing and developing video games for various platforms, including desktop, mobile, and console.`,
        `Enterprise software development :designing and developing software solutions for large organizations, including enterprise resource planning (ERP) and customer relationship management (CRM) systems.`,
        `Software maintenance and support : providing ongoing support and maintenance for existing software applications.`,
      ],
      sx: {
        background: "#F2FCFF 0% 0% no-repeat padding-box",
        border: "1px solid #87CBFF",
      },
      id: "SOFTWAREDEVELOPMENT",
    },

    {
      title: "TESTING / QA",
      desciption: `Our team focus on automating various testing and quality assurance processes to ensure the quality and reliability of software applications. Some of the most common testing/QA automation services available are:`,
      list: [
        `Unit testing : automated testing of individual units of code, such as functions or classes, to ensure they work as expected.`,
        `Integration testing : automated testing of multiple units of code working together to ensure they integrate and work correctly.`,
        `Functional testing : automated testing of the functionality of a software application to ensure it meets specified requirements.`,
        `Regression testing : automated testing of the previously tested software to ensure new changes have not introduced any unintended consequences.`,
        `Performance testing : automated testing to measure and evaluate the performance of a software application under various conditions, such as load, stress, and scalability.`,
        `Security testing : automated testing to identify and evaluate the security vulnerabilities of a software application.`,
        `User acceptance testing (UAT) : automated testing performed by end users to verify that a software application meets their needs and requirements.`,
        `Continuous integration and continuous delivery (CI/CD) : automated processes for building, testing, and deploying software changes continuously.`,
      ],
      sx: {
        background: "#F6F8FF 0% 0% no-repeat padding-box",
        border: "1px solid #88A3FF",
      },
      id: "TESTING",
    },
    {
      title: "CLOUD CENTER",
      desciption: `
      Our team comprehends that cloud solutions aren't one-size-fits-all. Whether your organization adopts a cloud-first mindset, a hybrid cloud approach, or is new to cloud deployment, we collaborate to craft robust strategies and features. We emphasize four key pillars, ensuring tailored, effective cloud solutions.`,
      list: [
        `Strategy and Architecture:["Core Infrastructure (Networking, Compute, & Storage)","Applications & DevOps","IoT","Integration & Automation","Identity & Access Management (IAM)","Windows, Devices, & VDI"]`,
        `Modern Workplace:["Identity & Access","Cloud Security & Purview","Messaging & Unified Communications","Collaboration & Productivity Platforms","Integration, Automation, & Customization","Business Apps & Advanced Workloads"]`,
        `Security:["Cloud Identity","IAM Security","SSO B2B/B2C","Microsoft Azure Security","Penetration Testing","Vulnerability Scanning","Policy Development & Adoption Strategy Advisory"]`,
      ],
      sx: {
        background: "#F8EFEA 0% 0% no-repeat padding-box",
        border: "1px solid #76AD92",
      },
      id: "CLOUDCENTER",
    },
    {
      title: "Network Security",
      desciption:
        `Today's cybersecurity landscape extends far beyond physical boundaries, posing challenges for organizations. NewHatch Networks offers expertise in securing cloud computing, collaboration tools, and web applications, ensuring workforce accessibility while safeguarding assets. Our solutions address evolving cyber threats, providing robust network security solutions.`,
      list: [
        `Assets and Sensitive Data : A sound network security strategy starts with understanding and controlling the sensitive data stored in your data center. Developing strong, consistent standards for your users assists in keeping your environment secure. From strategy to deployment, ensuring your organization’s assets and sensitive data are accounted for and follow the policies and rules of the organization is the first line of defense for network security.`,
        `Compliance : Just like our Network Security practice, compliance is crucial for your organization to meet security goals. Understanding compliance regulations and addressing internal policy issues are essential for a robust security strategy. Our team assists in navigating business regulations, identifies policy concerns, and devises cybersecurity solutions tailored to your needs. We offer recommendations for data security tools to ensure compliance requirements are met.`,
        `Monitoring : Whether you are monitoring what is happening in your workspaces or reviewing incident logs pulled from tools in place in your environment, monitoring is crucial to maintaining network security. 
        Uncovering potential threats in an active workspace and mitigating the threat or dealing with incidents and executing a robust response plan, monitoring is a key to a sound security posture. Our team supports several platforms that take the guess work and resources out of monitoring.`,
        `Management : As with Network Security, managing your policies, best practices, and remediation are also key elements to successful cloud security. Organizations need to provide stakeholders high levels of assurance that the organization’s assets are secure. 
        Organizations need to ensure their documentation of policies and best practices remains up to date, understand their service-level agreements with their provider, and make quick remediations to any issues. 
        Our team can provide the strategy, solutions, and expertise to make your cloud security strategy and deployment successful.`,
      ],
      sx: {
        background: "#F2FCFF 0% 0% no-repeat padding-box",
        border: "1px solid #87CBFF",
      },
      id: "NETWORKSECURITY",
    },
    {
      title: "CYBERSECURITY",
      desciption:
        "Our Team offers to protect organisations and individuals from cyber threats, such as hacking, malware, and data breaches. Some of the most common cybersecurity services available are:",
      list: [
        `Managed security services : Outsourcing the management of an organisation's security needs to a third-party provider.`,
        `Penetration testing : simulated attacks on a system to identify and evaluate security vulnerabilities.`,
        `Vulnerability scanning : automated scanning of systems to identify and prioritise security vulnerabilities.`,
        `Security information and event management (SIEM) : real-time analysis of security-related data to identify and respond to potential security incidents.`,
        `Firewall management : management of firewall devices to ensure the security of network traffic.`,
        `Identity and access management (IAM) : managing and controlling access to sensitive systems and data.`,
        `Encryption services : the use of encryption to protect sensitive data in transit and at rest.`,
        `Disaster recovery and business continuity planning : planning and preparation for responding to and recovering from a cybersecurity incident.`,
      ],
      sx: {
        background: "#F6F8FF 0% 0% no-repeat padding-box",
        border: "1px solid #88A3FF",
      },
      id: "CYBERSECURITY",
    },
    {
      title: "STAFF AUGEMENTATION",
      desciption: `Newhatch offers outsourcing of additional personnel to support an organisation's workforce. These services are used to supplement an organisation's existing staff with skilled professionals to address short-term or project-based needs. Some of the most common staff augmentation services available are:`,
      list: [
        `IT staff augmentation : Outsourcing of IT professionals to support an organisation's technology needs, such as software development and network administration.`,
        `Financial staff augmentation : outsourcing of financial professionals to support an organisation's accounting and financial reporting needs.`,
        `Administrative staff augmentation : outsourcing of administrative professionals to support an organisation's administrative tasks, such as data entry and customer service.`,
        `Marketing staff augmentation : outsourcing of marketing professionals to support an organisation's marketing and branding efforts.`,
      ],
      sx: {
        background: "#FFFCF9 0% 0% no-repeat padding-box",
        border: "1px solid #FFDDBB",
      },
      id: "STAFFAUGEMENTATION",
    },
    {
      title: "IoT development and consultancy",
      desciption: `Discover limitless possibilities with our IoT development and consultancy services. Tailored solutions, expert guidance, and innovative strategies empower businesses to thrive in the interconnected world. Stay ahead with our cutting-edge technology solutions designed for success.`,
      list: [
        `Customized IoT application development:Get tailor-made IoT applications to suit your unique requirements. Our development services ensure that your IoT solutions align perfectly with your business needs, delivering a seamless and efficient user experience.`,
        `Integration of IoT solutions with existing systems:Seamlessly integrate IoT solutions with your current systems. Our expertise ensures a smooth transition and optimal performance, enhancing the capabilities of your existing infrastructure.`,
        `Consultancy on IoT strategy and implementation:Receive expert consultancy on IoT strategy and implementation. Our guidance helps businesses navigate the complexities of IoT, enabling them to make informed decisions and achieve successful implementation.`,
        `IoT security and data analytics services:Ensure the security of your IoT ecosystem with our dedicated IoT security and data analytics services. Our solutions provide comprehensive protection and valuable insights, enhancing the overall reliability of your IoT infrastructure.`,
      ],
      sx: {
        background: "#FFFCF9 0% 0% no-repeat padding-box",
        border: "1px solid #7DBDAB",
      },
      id: "IOTDEVELOPMENT",
    },
    {
      title: "Industrial Automation(IIoT) ",
      desciption: `Revolutionise your industry with our industrial automation and consultancy services. Our tailored solutions optimize processes, enhance efficiency, and drive innovation. Trust us to elevate your operations, ensuring a seamless transition to the future of industrial excellence.`,
      list: [
        `IoT implementation for smart manufacturing:Revolutionize your manufacturing processes with our IoT implementation. Smart sensors, connectivity, and data analytics converge to create an intelligent ecosystem for efficient and informed decision-making.`,
        `Industrial automation solutions for process optimization:Enhance efficiency and optimize your industrial processes with our automation solutions. From robotic automation to intelligent control systems, we provide comprehensive solutions for industrial excellence.`,
        `Consultancy on Industry 4.0 strategies:Navigate the landscape of Industry 4.0 with our expert consultancy services. We offer strategic insights and guidance to help your business leverage the full potential of the latest industrial technologies.`,
        `Integration of automation systems with existing infrastructure:Seamlessly integrate automation into your existing infrastructure. Our solutions ensure a harmonious blend of new technologies with your current systems, enhancing overall performance and efficiency.`,
      ],
      sx: {
        background: "#F8EFEA 0% 0% no-repeat padding-box",
        border: "1px solid #76AD92",
      },
      id: "INDUSTRIALAUTOMATION",
    },
    {
      title: "Generative AI solutions",
      desciption: `Transform possibilities into reality with our Generative AI solutions. Unleash creativity and efficiency through cutting-edge technology. Tailored to your needs, our solutions redefine innovation, paving the way for a future where AI shapes limitless possibilities for your business.`,
      list: [
        `Generative AI for creative content creation:Explore the realm of creative possibilities with our Generative AI solutions. From imaginative content generation to artistic expression, our AI empowers creativity at its best.`,
        `Efficient automation of complex tasks through AI:Streamline and enhance your operational efficiency with our AI-driven automation solutions. Tackle intricate tasks with ease, optimizing processes for maximum productivity.`,
        `Customized AI solutions for unique business:Tailor-made AI solutions to suit the distinctive needs of your business. Our customized approach ensures that AI aligns seamlessly with your unique requirements, unlocking new possibilities.`,
        `Integration of generative models in your workflow:Revolutionize your workflow by integrating advanced generative models. Our AI solutions seamlessly blend into your processes, adding innovation and efficiency to every step.`,
      ],
      sx: {
        background: "#E4F1F6 0% 0% no-repeat padding-box",
        border: "1px solid #58A4B0",
      },
      id: "GENERATIVEAI",
    },
  ],
  faqs: [
    {
      question: "I am looking for a job, can Newhatch help me?",
      answer:
        "Yes. Newhatch places consultants in three different ways: direct placement, contract-to-hire, and contract positions. Connect with a local recruiter to discuss our openings and your options.",
    },
    {
      question:
        " I have never used a recruiting service before; how do I get started?",
      answer: `There are multiple options to get started. You can learn about current openings within given locations. Once you find a job listing of interest, select it to view a snapshot of the position and if interested, you may select the 'apply' button.`,
    },
    {
      question:
        "Why should I use Newhatch to find my next position instead of responding to job postings and advertisements?",
      answer:
        "Not all positions are advertised to the general public. Newhatch has relationships with thousands of clients in all industries and markets throughout North America. To ensure that your resume receives the attention it deserves, our staff personally works with hiring managers to find your next career opportunity. We are here for you and unlike some other firms, our service is a free career resource.",
    },
    {
      question: "In what types of jobs does Newhatch specialize?",
      answer:
        "Newhatch specializes in a wide range of career opportunities within core disciplines that include Application and Data Management, Digital Experience and Content Strategy, Engineering, Infrastructure and Security, PMO and Business Operations, and Scientific.Connect with a local recruiter to discuss current opportunities.",
    },
    {
      question: "What is the process once I submit my resume to Newhatch?",
      answer:
        "If a recruiter thinks you are a good match, you may be contacted for an initial interview. During this time, the recruiter will ask questions about your professional history, skills, and work preferences. The more we know about you, the better we can match you to your dream position. Once interviews are completed, our recruiter will contact you with positions that are suited for your area of expertise as they become available. Remember that we are here for you. These initial steps help us build a relationship with you as you become a part of the Newhatch family. Our goal is to connect talented professionals like you with the right opportunity.",
    },
    {
      question: "What companies or types of companies does Newhatch work with?",
      answer:
        "We serve Fortune 500 companies, mid-market,and emerging companies from a variety of industries including Business Services, Communications, Consumer Products and Services, Energy, Engineering, Financial Services, Food, Drug, and Beverage, Government Services, Healthcare, Hospitality, Insurance, Life Sciences, Retail and Digital Commerce, Technology, and Transportation.",
    },
    {
      question:
        "What kind of background or skills do I need to work with Newhatch?",
      answer:
        "We support job seekers with diverse backgrounds, skills, accomplishments, and skill levels. Whether you’re interested in an entry-level position or something more senior, like management, our goal remains the same: matching you with your next opportunity.",
    },
  ],
};

const LEADERSHIP_PAGE_CONTENT = {
  ourMissions: [
    "Providing high-quality technology consulting services that help organizations make informed technology decisions and improve their IT systems and processes.",
    "Delivering innovative and practical technology solutions that support the unique needs and requirements of each client.",
    "Building long-term relationships with clients based on trust, transparency, and mutual success.",
    "Continuously improving and updating its consulting services to stay at the forefront of technology and meet the evolving needs of clients.",
    "Providing a supportive and rewarding work environment for its employees and fostering a culture of innovation and collaboration.",
  ],
};

const HOME_PAGE_CONTENT = {
  ourServices: [
    {
      title: "TECHNOLOGY CONSULTING",
      desciption:
        "Newhatch team offers to help organizations plan, design, and implement technology solutions.",
      logo: "robot-arm",
      id: "TECHNOLOGYCONSULTING",
    },
    {
      title: "SOFTWARE DEVELOPMENT",
      desciption:
        "Our developers offer to design, develop, and maintain software applications.",
      logo: "diversity",
      id: "SOFTWAREDEVELOPMENT",
    },
    {
      title: "TESTING / QA",
      desciption:
        "Our team focus on automating various testing and quality assurance processes to ensure the quality and reliability of software applications.",
      logo: "terminal",
      id: "TESTING",
    },
    {
      title: "CLOUD CENTER",
      desciption:
        "Cloud solutions aren't uniform. We work with your organization, whether cloud-first, hybrid, or new to deployment, to craft effective strategies. Our approach emphasizes four key pillars, ensuring tailored, effective cloud solutions.",
      logo: "cloud",
      id: "CLOUDCENTER",
    },
    {
      title: "Network Security",
      desciption:
        "NewHatch Networks safeguards cloud, collaboration, and web apps, ensuring workforce accessibility and asset protection. Our expertise tackles emerging cyber threats, delivering robust network security solutions.",
      logo: "verified-user",
      id: "NETWORKSECURITY",
    },
    {
      title: "CYBERSECURITY",
      desciption:
        "Our Team offers to protect organizations and individuals from cyber threats, such as hacking, malware, and data breaches.",
      logo: "arrow",
      id: "CYBERSECURITY",
    },
    {
      title: "STAFF AUGEMENTATION",
      desciption: `Newhatch offers outsourcing of additional personnel to support an organization's workforce. These services are used to supplement an organization's existing staff with skilled professionals to address short-term or project-based need.`,
      logo: "verified-user",
      id: "STAFFAUGEMENTATION",
    },
    {
      title: "GENERATIVE AI SOLUTIONS",
      desciption: `Unlock limitless potential with NewHatch's Generative AI. Harness innovation for business growth. Our cutting-edge solutions redefine creativity and efficiency, paving the way for a future where AI shapes limitless possibilities for your business.`,
      logo: "verified-user",
      id: "GENERATIVEAI",
    },
  ],
  testimonialList: [
    {
      designation: "CEO",
      name: "",
      currentCompany: "INSIGHT DATA LLC",
      review:
        "I recently worked with NEWHATCH LLC and was thoroughly impressed with their services. The team was knowledgeable, responsive, and always available to help with any questions or concerns. They provided valuable insights and recommendations, helping my company optimize our technology systems and processes",
      date: "",
    },
    {
      designation: "CEO",
      name: "",
      currentCompany: "INFOTECH LLC",
      review:
        "I was extremely impressed with their level of expertise and professionalism throughout the entire process. The consultant assigned to our project was knowledgeable, communicative, and truly cared about delivering the best solution for our needs. The project was delivered on time and within budget, and the results exceeded our expectations",
      date: "",
    },
    {
      designation: "SR. JAVA DEVELOPER",
      name: "SMITH PATEL",
      currentCompany: "WALMART LABS",
      review:
        "I recently completed a Java bootcamp and was pleased with the placement support provided by the program. The team worked tirelessly to connect me with potential employers, and they provided valuable advice on job searching and interview preparation. I was able to secure a great job in a short amount of time thanks to their efforts.",
      date: "",
    },
    {
      designation: "QA AUTOMATION ENGINEER",
      name: "KRUTI PATEL",
      currentCompany: "STAPLES",
      review:
        "I recently completed a QA Automation training and was impressed with the placement support provided. I was able to secure a great job in a short amount of time, and I attribute this success to the support I received from the placement team. I highly recommend this QA Automation course for anyone looking to enter the field or advance their career.",
      date: "",
    },
    {
      designation: "SR DATA ANALYST",
      name: "NARDIP SINGH",
      currentCompany: "APPLE",
      review:
        "I recently completed a Data Analyst bootcamp and was pleased with the placement support provided. The instructors were knowledgeable and experienced in the field, and they went above and beyond to help me find a job as a data analyst. They provided valuable advice on resume writing, job searching, and interview preparation, and they connected me with potential employers.",
      date: "",
    },
    {
      designation: "SR DATA ENGINEER",
      name: "SAM TORRES",
      currentCompany: "SAMSUNG",
      review:
        "Newhatch team members were knowledgeable and experienced in the field, and they taught the material in an engaging and effective manner. The curriculum covered a wide range of topics, from data storage and processing to data visualization and presentation. I was able to secure a great job in a short amount of time, and I attribute this success to the support I received from the placement team.",
      date: "",
    },
    {
      designation: "SR UI/UX DEVELOPER",
      name: "GAEL SANCHEZ",
      currentCompany: "FIDELITY INVESTMENTS",
      review:
        "The training curriculum covered a wide range of topics, from HTML, CSS, and JavaScript to modern frontend frameworks and libraries. The hands-on projects allowed me to apply what I learned and build a strong portfolio. The placement support was also excellent. The team went above and beyond to connect me with potential employers and provided valuable advice on job searching and interview preparation.",
      date: "",
    },
    {
      designation: "DATA ANALYST",
      name: "HARSHA REDDY",
      currentCompany: "PNC BANK",
      review:
        "I recently completed a Data Analyst training and was impressed with the placement support provided. I was able to secure a great job in a short amount of time, and I attribute this success to the support I received from the placement team. I highly recommend this Data Analyst course for anyone looking to enter the field or advance their career.",
      date: "",
    },
    {
      designation: "SR DOT NET DEVELOPER",
      name: "NIRAV SHAH",
      currentCompany: "CLEVELAND CLINIC",
      review:
        "The marketing team was knowledgeable and experienced in the field, and they went above and beyond to help me find a job as a .NET developer. I was able to secure a great job in a short amount of time, and I attribute this success to the support I received from the placement team. The program provides a solid foundation in .NET development, and the placement support is exceptional. The team truly cares about their students and is dedicated to their success.",
      date: "",
    },
  ],
  companyOutput: [
    {
      title: "Experience",
      value: "10+",
    },
    {
      title: "Placement",
      value: "120+",
    },
    {
      title: "Companies",
      value: "80+",
    },
    {
      title: "Unicorns",
      value: "10+",
    },
  ],
};

export {
  ABOUT_US_PAGE_CONTENT,
  SERVICES_PAGE_CONTENT,
  LEADERSHIP_PAGE_CONTENT,
  HOME_PAGE_CONTENT,
};
