// ParticleBackground.js
import React, { useEffect } from 'react';
import 'particles.js';

const ParticleBackground = () => {
  useEffect(() => {
    window.particlesJS('particles-js',{
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: ['#2F59A6', '#42BDE9'],
        },
        shape: {
          type: ['circle', 'edge', 'triangle', 'polygon', 'star'],
          stroke: {
            width: 0,
            color: '#000000',
          },
          polygon: {
            nb_sides: 5,
          },
        },
        opacity: {
          value: 0.7,
          random: true,
        },
        size: {
          value: 17,
          random: true,
        },
        line_linked: {
          enable: false,
        },
        move: {
          enable: true,
          speed: 3,
          direction: 'none',
          random: true,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: true,
            rotateX: 3000,
            rotateY: 3000,
          },
        },
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {
            enable: true,
            mode: 'repulse',
          },
        },
        modes: {
          repulse: {
            distance: 100,
            duration: 0.4,
            speed:10,
          },
        },
      },
      retina_detect: true,
    });
  }, []);


  return <div id="particles-js"></div>;
};

export default ParticleBackground;
