import {
  Box,
  Button,
  Collapse,
  Link,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
const SideNav = () => {
  const [isOpen, setisOpen] = useState(false);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  const toggleDrawer = (index) => () => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");

  const styles = {
    imageContainer: {
      height: "45px",
      width: isMobile ? "200px" : isTablet ? "240px" : "270px",
      display: "block",
      backgroundImage: `url(${require("../../assets/logo.jpg")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
      borderRadius: "16px",
    },
  };
  const sideNavAdminLinks = [
    {
      title: "Jobs",
      subLinks: [
        { title: "Post Jobs", url: "/admin/postJob" },
        { title: "View Jobs", url: "/admin/viewJob" },
      ],
      url: "",
      disabled: false,
    },
    {
      // { title: 'Send recurring mails', url: '/' },
      title: "Marketing",
      subLinks: [
        { title: "View Reach outs", url: "/admin/viewReachOuts" },
        { title: "View User Feedbacks", url: "/admin/userFeedbacks" },
      ],
      url: "",
      disabled: false,
    },
    {
      title: "Applications",
      subLinks: [{ title: "View Applications", url: "/" }],
      url: "",
      disabled: true,
    },
  ];
  return (
    <React.Fragment key={"drawer-left-side-nav"}>
      <Button
        aria-label="side-nav button"
        onClick={() => setisOpen(true)}
        sx={{ width: "max-content", background: "white" }}
      >
        <MenuIcon sx={{ width: "50px" }} />
      </Button>
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onOpen={() => console.log("isopen")}
        onClose={() => setisOpen(false)}
        sx={{ width: "30vw" }}
      >
        <List>
          <ListItemButton
            sx={{
              padding: "0px",
              display: "flex",
              width: "max-content",
              height: "55px",
            }}
          >
            <Link href="/">
              <Box sx={styles.imageContainer} />
            </Link>
            <Button
              component={"div"}
              sx={{
                ":hover": {
                  color: "white",
                  fontWeight: "700",
                  background: "#2F59A6",
                },
              }}
              onClick={() => setisOpen(false)}
            >
              {" "}
              <CloseIcon />{" "}
            </Button>
          </ListItemButton>
          {sideNavAdminLinks.map((item, index) => (
            <React.Fragment key={index}>
              <ListItemButton
                disabled={item.disabled}
                onClick={
                  item.subLinks ? toggleDrawer(index) : toggleDrawer(null)
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <ListItemText primary={item.title} />{" "}
                  {openMenuIndex === index ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </Box>
              </ListItemButton>
              {item.subLinks && (
                <Collapse in={openMenuIndex === index}>
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      background:
                        "linear-gradient(rgb(47,89,166),rgb(56,139,200), rgb(66,189,233), rgb(255,255,255))",
                    }}
                  >
                    {item.subLinks.map((subItem, subIndex) => (
                      <ListItemButton
                        key={subIndex}
                        component={Link}
                        to={subItem.url}
                      >
                        <ListItemText primary={subItem.title} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default SideNav;
