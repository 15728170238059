import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container } from '@mui/material';


function PageNotFound() {
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center',
        },
        emoji: {
            fontSize: '5rem',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '2rem',
            marginBottom: '10px',
        },
        message: {
            fontSize: '1rem',
            marginBottom: '30px',
        },
        link: {
            color: '',
            textDecoration: 'none',
            fontWeight: 'bold',
        },
    }

    return (
        <Box sx={styles.container}>
            <Container>
                <span className={styles.emoji}>🙈</span>
                <h2 className={styles.heading}>Oops! Page Not Found</h2>
                <p className={styles.message}>
                    We couldn't find the page you're looking for.
                </p>
                <p className={styles.message}>
                    You might want to go back <Link to="/" className={styles.link}>home</Link>.
                </p>
            </Container>
        </Box>
    );
}

export default PageNotFound;
