import { createContext, useContext, useEffect, useState } from "react";
import { auth, googleProvider } from "../firebase";
import { signInWithPopup } from "firebase/auth";


const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setcurrentUser] = useState();
    // const [loading, setloading] = useState(true)
    function signIn() {
        return signInWithPopup(auth, googleProvider);
    }

    function logout() {
        return auth.signOut()
    }


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setcurrentUser(user)
            // setloading(false)
        })

        return unsubscribe
    }, [])



    return (<AuthContext.Provider value={{
        currentUser,
        signIn,
        logout
    }}>{children && children}</AuthContext.Provider>)
}