import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IntroBanner from "../components/IntroBanner";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import { useParams } from "react-router-dom";
import { SERVICES_PAGE_CONTENT } from "../content/pageStaticContent";
import { capitalizeFirstLetter } from "../utils/common";


function Service() {
  const { slug } = useParams();
  // const ourServices = getServicesList();
  const { ourServices, faqs } = SERVICES_PAGE_CONTENT;
  //   const faqs = [
  //     {
  //       question: "I am looking for a job, can Newhatch help me?",
  //       answer:
  //         "Yes. Newhatch places consultants in three different ways: direct placement, contract-to-hire, and contract positions. Connect with a local recruiter to discuss our openings and your options.",
  //     },
  //     {
  //       question:
  //         " I have never used a recruiting service before; how do I get started?",
  //       answer: `There are multiple options to get started. You can learn about current openings within given locations. Once you find a job listing of interest, select it to view a snapshot of the position and if interested, you may select the 'apply' button.`,
  //     },
  //     {
  //       question:
  //         "Why should I use Newhatch to find my next position instead of responding to job postings and advertisements?",
  //       answer:
  //         "Not all positions are advertised to the general public. Newhatch has relationships with thousands of clients in all industries and markets throughout North America. To ensure that your resume receives the attention it deserves, our staff personally works with hiring managers to find your next career opportunity. We are here for you and unlike some other firms, our service is a free career resource.",
  //     },
  //     {
  //       question: "In what types of jobs does Newhatch specialize?",
  //       answer:
  //         "Newhatch specializes in a wide range of career opportunities within core disciplines that include Application and Data Management, Digital Experience and Content Strategy, Engineering, Infrastructure and Security, PMO and Business Operations, and Scientific.Connect with a local recruiter to discuss current opportunities.",
  //     },
  //     {
  //       question: "What is the process once I submit my resume to Newhatch?",
  //       answer:
  //         "If a recruiter thinks you are a good match, you may be contacted for an initial interview. During this time, the recruiter will ask questions about your professional history, skills, and work preferences. The more we know about you, the better we can match you to your dream position. Once interviews are completed, our recruiter will contact you with positions that are suited for your area of expertise as they become available. Remember that we are here for you. These initial steps help us build a relationship with you as you become a part of the Newhatch family. Our goal is to connect talented professionals like you with the right opportunity.",
  //     },
  //     {
  //       question: "What companies or types of companies does Newhatch work with?",
  //       answer:
  //         "We serve Fortune 500 companies, mid-market,and emerging companies from a variety of industries including Business Services, Communications, Consumer Products and Services, Energy, Engineering, Financial Services, Food, Drug, and Beverage, Government Services, Healthcare, Hospitality, Insurance, Life Sciences, Retail and Digital Commerce, Technology, and Transportation.",
  //     },
  //     {
  //       question:
  //         "What kind of background or skills do I need to work with Newhatch?",
  //       answer:
  //         "We support job seekers with diverse backgrounds, skills, accomplishments, and skill levels. Whether you’re interested in an entry-level position or something more senior, like management, our goal remains the same: matching you with your next opportunity.",
  //     },
  //   ];
  const [selectedFaq, setselectedFaq] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");
  const styles = {
    fullScreenBanner1: {
      display: "flex",
      backgroundAttachment: "fixed",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "center center/cover no-repeat",
      backgroundSize: "100% 100%",
      color: "#fff",
      margin: " 0 auto",
      position: "relative",
      maxWidth: "100%",
      height: "95vh",
      background: "linear-gradient(to right top,#FFFFFF, #388BC8)",
    },
    fullScreenBanner2: {
      display: "flex",
      backgroundAttachment: "fixed",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "center center/cover no-repeat",
      backgroundSize: "100% 100%",
      color: "#fff",
      margin: " 0 auto",
      position: "relative",
      maxWidth: "100%",
      height: "10vh",
      paddingTop: "40px",
    },
    fullScreenBanner3: {
      minHeight: "200px",
      height: "100%",
      width: "100%",
      background: `url(${require("../assets/intro-banner.png")})`,
      backgroundSize: "cover",
      objectFit: "cover",
      // mixBlendMode: 'color-burn',
      borderRadius: "15px",
    },
    imageContainer2: {
      height: isMobile ? "300px" : "332px",
      width: isMobile ? "200px" : isTablet ? "240px" : "280px",
      display: "block",
      backgroundImage: `url(${require("../assets/services-insurance.png")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
      borderRadius: "16px",
    },
    imageContainer: {
      height: "397px",
      width: isMobile ? "100%" : isTablet ? "340px" : "437px",
      display: "block",
      backgroundImage: `url(${require("../assets/about-heading.png")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
      borderRadius: "12px",
    },
    imageContainerOverlapp: {
      height: isMobile ? "400px" : "257px",
      width: isMobile ? "360px" : isTablet ? "300px" : "283px",
      display: "block",
      backgroundImage: `url(${require("../assets/service-intro2.png")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
      position: isMobile ? "relative" : "absolute",
      bottom: "0",
      right: "0",
      borderRadius: "12px",
    },
  };
  useEffect(() => {
    if (slug) {
      setTimeout(() => {
        const id = slug;
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  });
  return (
    <>
      <IntroBanner>
        <Typography
          sx={{
            color: "#004DD8",
            letterSpacing: "0px",
            paddingBottom: "40px",
          }}
          variant="h3"
        >
          {"Services"}
        </Typography>
        <Divider
          variant="fullWidth"
          sx={{ border: "2px solid #FF6525", width: "96px" }}
        />
        <Typography
          sx={{ paddingTop: "22px", width: "100%", textAlign: "center" }}
        >
          {
            "We find right job for you. strategic mind for your company growth. our team , your advantage!"
          }
        </Typography>
      </IntroBanner>
      {/* OUR SERVICES */}
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
            gap: isMobile ? "40px" : isTablet ? "30px" : "119px",
            height: "100%",
            width: "100%",
            paddingTop: "80px",
            paddingBottom: "80px",
          }}
        >
          <Box sx={{ position: "relative", height: "540px", width: "480px" }}>
            {!isMobile ? <Box sx={styles.imageContainer} /> : <></>}
            <Box sx={styles.imageContainerOverlapp} />
          </Box>
          <Box sx={{ textAlign: "left" }}>
            <Typography
              sx={{ color: "#848484", fontSize: "14px", paddingBottom: "5px" }}
            >
              {"SERVICES"}
            </Typography>
            <Typography
              sx={{
                color: "#004DD8",
                fontSize: "34px",
                paddingBottom: "20px",
                width: "310px",
                letterSpacing: "0px",
                textAlign: "left",
              }}
            >
              {"Unlocking potential, Delivering results."}
            </Typography>
            <Divider
              variant="fullWidth"
              sx={{ border: "2px solid #FF6525", width: "96px" }}
            />
            <Typography
              sx={{
                color: "#606060",
                fontSize: "16px",
                width: isMobile ? "auto" : isTablet ? "280px" : "300px",
                paddingTop: "24px",
              }}
            >
              {
                "We find right job for you. strategic mind for your company growth. Our team , your advantage!"
              }
            </Typography>
          </Box>
        </Box>
      </Container>

      <Container sx={{ paddingBottom: "60px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            padding: "20px 0px 40px 0px",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontSize: "14px",
              color: "#848484",
              letterSpacing: "5.04px",
            }}
          >
            {"SERVICES"}
          </Typography>
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontSize: "34px",
              color: "#004DD8",
            }}
          >
            {"Explore our varied areas of expertise"}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: isMobile
              ? "1fr"
              : isTablet
                ? "1fr 1fr"
                : "1fr 1fr 1fr",
            gap: "30px",
          }}
        >
          {ourServices.map((ele, idx) => (
            <Box
              key={`${ele.id}-${idx}`}
              onClick={() => window.location.href = `/detail/${ele.id.toLowerCase()}`}
              sx={{
                display: "flex",
                cursor: 'pointer',
                flexDirection: "column",
                height: "380px",
                justifyContent: "space-between",
                padding: isMobile ? "20px" : "30px",
                borderRadius: "18px",
                boxShadow: "0px 7px 23px #0000001C",
                ...ele.sx,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#004DD8",
                    fontSize: "20px",
                    textAlign: "left",
                    width: isMobile ? "100%" : "156px",
                    paddingBottom: "26px",
                    height: isMobile ? "max-content" : "55px",
                  }}
                >
                  {capitalizeFirstLetter(ele.title)}
                </Typography>
                <Divider
                  variant="fullWidth"
                  sx={{ border: "2px solid #FF6525", width: "96px" }}
                />
                <Typography
                  sx={{
                    color: "",
                    fontSize: "",
                    textAlign: "left",
                    width: isTablet ? "270px" : "309px",
                    paddingTop: "26px",
                  }}
                >
                  {ele.desciption}
                </Typography>
              </Box>

              <Button
                href={`/detail/${ele.id.toLowerCase()}`}
                sx={{
                  background:
                    "transparent linear-gradient(105deg, #004DD8 0%, #41BEEA 100%) 0% 0% no-repeat padding-box",
                  padding: "12px 30px",
                  borderRadius: "5px",
                  width: "170px",
                  boxShadow: "0px 7px 23px #0000001C",
                }}
              >
                <Typography sx={{ color: "white", fontSize: "18px" }}>
                  {"View more"}
                </Typography>
              </Button>
            </Box>
          ))}
        </Box>
      </Container>
      {/* CAREER TRAINING AND LIFESTYLE */}
      <Container
        sx={{
          padding: "40px 0px",
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "max-content",
            height: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            columnGap: "60px",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: "30px" }}
          >
            <Typography
              sx={{
                color: "#004DD8",
                fontSize: "34px",
                fontWeight: "500",
                textAlign: isMobile ? "center" : "left",
                width: isMobile ? "350px" : "390px",
              }}
            >
              {"Career Training, Lifestyle Discounts, and Perks"}
            </Typography>
            <Divider
              variant="fullWidth"
              sx={{ border: "2px solid #FF6525", width: "96px" }}
            />
            <Typography
              sx={{
                color: "#606060",
                fontSize: "16px",
                width: "auto",
                maxWidth: isMobile ? "100%" : isTablet ? "320px" : "356px",
                textAlign: isMobile ? "center" : "left",
              }}
            >{`To help progress your career path, we have professional development programs and training to propel you forward. We also provide lifestyle discounts and perks to help you along the way.`}</Typography>
          </Box>

          <List
            sx={{ display: "flex", rowGap: "15px", flexDirection: "column" }}
          >
            <ListItem
              sx={{
                display: "flex",
                columnGap: "10px",
                justifyContent: isMobile ? "center" : "flex-start",
                padding: "0px",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Typography>
                <Typography sx={{ color: "#FF6525", fontWeight: "600" }}>
                  {"Toolbox Talks -"}
                </Typography>

                {
                  "Career development webinars of technical and development topics led by subject matter experts"
                }
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                columnGap: "10px",
                justifyContent: isMobile ? "center" : "flex-start",
                padding: "0px",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Typography>
                <Typography sx={{ color: "#FF6525", fontWeight: "600" }}>
                  {"Training Resources -"}
                </Typography>
                {"Includes training discounts and career development programs"}
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                columnGap: "10px",
                justifyContent: isMobile ? "center" : "flex-start",
                padding: "0px",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Typography>
                <Typography sx={{ color: "#FF6525", fontWeight: "600" }}>
                  {"Discount Programs -"}
                </Typography>
                {
                  "Such as travel, retail, wholesale clubs, events, and wireless plans"
                }
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                columnGap: "10px",
                justifyContent: isMobile ? "center" : "flex-start",
                padding: "0px",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Typography>
                <Typography sx={{ color: "#FF6525", fontWeight: "600" }}>
                  {"Referral Bonus -"}
                </Typography>
                {"Earn money for your referrals"}
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Container>
      {/* INSURANCE AND RETIREMENT */}
      <Container
        sx={{
          padding: "40px 0px",
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: "30px" }}
          >
            <Typography
              sx={{
                color: "#004DD8",
                fontSize: "34px",
                fontWeight: "500",
                textAlign: isMobile ? "center" : "left",
                width: isMobile ? "100%" : "319px",
              }}
            >
              {"Insurance and Retirement Savings."}
            </Typography>
            <Divider
              variant="fullWidth"
              sx={{ border: "2px solid #FF6525", width: "96px" }}
            />
            <Typography
              sx={{
                color: "#606060",
                fontSize: "16px",
                width: "auto",
                maxWidth: isMobile ? "100%" : isTablet ? "280px" : "312px",
                textAlign: isMobile ? "center" : "left",
              }}
            >{`We offer the following benefits options that suit your needs.`}</Typography>
          </Box>
          <Box
            sx={{
              width: isMobile ? "100%" : "max-content",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={styles.imageContainer2} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
              justifyContent: "flex-start",
            }}
          >
            <List
              sx={{ display: "flex", rowGap: "15px", flexDirection: "column" }}
            >
              <ListItem
                sx={{
                  display: "flex",
                  columnGap: "10px",
                  justifyContent: isMobile ? "center" : "flex-start",
                  padding: "0px",
                }}
              >
                <AddOutlinedIcon sx={{ color: "#FF6525", fontWeight: "600" }} />
                <Typography sx={{ width: isMobile ? "180px" : "max-content" }}>
                  {"Health Insurance"}
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "flex",
                  columnGap: "10px",
                  justifyContent: isMobile ? "center" : "flex-start",
                  padding: "0px",
                }}
              >
                <AddOutlinedIcon sx={{ color: "#FF6525", fontWeight: "600" }} />
                <Typography sx={{ width: isMobile ? "180px" : "max-content" }}>
                  {"Vision Insurance"}
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "flex",
                  columnGap: "10px",
                  justifyContent: isMobile ? "center" : "flex-start",
                  padding: "0px",
                }}
              >
                <AddOutlinedIcon sx={{ color: "#FF6525", fontWeight: "600" }} />
                <Typography sx={{ width: isMobile ? "180px" : "max-content" }}>
                  {"Dental Insurance"}
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "flex",
                  columnGap: "10px",
                  justifyContent: isMobile ? "center" : "flex-start",
                  padding: "0px",
                }}
              >
                <AddOutlinedIcon sx={{ color: "#FF6525", fontWeight: "600" }} />
                <Typography sx={{ width: isMobile ? "180px" : "max-content" }}>
                  {"Life Insurance"}
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "flex",
                  columnGap: "10px",
                  justifyContent: isMobile ? "center" : "flex-start",
                  padding: "0px",
                }}
              >
                <AddOutlinedIcon sx={{ color: "#FF6525", fontWeight: "600" }} />
                <Typography sx={{ width: isMobile ? "180px" : "max-content" }}>
                  {"401k retirement plan (U.S. only)"}
                </Typography>
              </ListItem>
            </List>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"div"}
                sx={{
                  color: "Red",
                  fontSize: isMobile ? "12px" : "14px",
                  fontWeight: "400",
                  width: "318px",
                }}
              >
                <Typography
                  sx={{ color: "Red", fontSize: "14px", fontWeight: "600" }}
                >
                  {"Disclaimer:"}
                </Typography>
                {"The above benefits may vary from position to position."}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>

      {/* FAQS */}
      <Container id={"faqs"} sx={{ padding: "0px 0px 60px 0px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            padding: "20px 0px 40px 0px",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              textAlign: "left",
              fontWeight: "600",
              fontSize: "18px",
              color: "#004DD8",
              letterSpacing: "5.04px",
            }}
          >
            {"FAQ`S"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
          {faqs.map((ele, idx) => (
            <Box>
              <Box
                key={`${ele.question.charAt(0)}-${idx}`}
                sx={{ display: "flex", columnGap: "10px" }}
              >
                <Box
                  component={"div"}
                  onClick={() =>
                    setselectedFaq(
                      selectedFaq !== null && selectedFaq === idx ? null : idx
                    )
                  }
                  sx={{ cursor: "pointer" }}
                >
                  {selectedFaq !== null && selectedFaq === idx ? (
                    <RemoveOutlinedIcon
                      sx={{ color: "#FF6525", fontWeight: "600" }}
                    />
                  ) : (
                    <AddOutlinedIcon
                      sx={{ color: "#FF6525", fontWeight: "600" }}
                    />
                  )}
                </Box>

                <Box
                  component={"div"}
                  onClick={() => setselectedFaq(idx)}
                  sx={{
                    display: "flex",
                    rowGap: "15px",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <Typography sx={{ color: "black" }}>
                    {ele.question}
                  </Typography>
                  <Box sx={{ display: "flex", columnGap: "10px" }}>
                    <Box
                      sx={{
                        borderLeft: "#FF6525 2px solid",
                        width: "5px",
                      }}
                    />
                    <Typography
                      sx={{
                        display:
                          selectedFaq !== null && selectedFaq === idx
                            ? "block"
                            : "none",
                        width: isMobile ? "300px" : "700px",
                        color: "#AFAFAF",
                      }}
                    >
                      {ele.answer}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
      {/* CONTACTUS - BANNER */}
      <Container>
        <Box sx={styles.fullScreenBanner3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "230px",
              opacity: "0.77",
              background:
                "linear-gradient(106deg, #0659DA 20%, #3EB9E9 80%) 0% 0% no-repeat padding-box",
              padding: "40px 0px",
              borderRadius: "20px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "900",
                fontSize: "1.8em",
                color: "white",
              }}
            >
              {"Ready to kickstart your tech career ?"}
            </Typography>
            <Box sx={{ textAlign: "center", padding: "20px 0px" }}>
              <Button
                sx={{
                  color: "#004DD8",
                  background: "white",
                  ":hover": {
                    background: "white",
                    outline: "2px solid #3EB9E9",
                  },
                }}
                size="medium"
              >
                <Link
                  href="/contact"
                  sx={{
                    textAlign: "center",
                    fontWeight: "900",
                    fontSize: "1.8em",
                    textDecoration: "none",
                  }}
                >
                  {"Join us now!"}
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Service;
