import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Link,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { getNavsProps } from "../../services/staticService";
import SideNav from "./SideNav";

export const Header = ({ isDark = false }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeNav, setActiveNav] = useState(-1);
  // const [anchorEl, setAboutAnchorEl] = React.useState(null);
  // const [leaderAnchorEl, setLeaderAnchorEl] = React.useState(null);
  const [navProps, setNavProps] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");
  // const [isOpen, setIsOpen] = useState(false);

  const refArray = [];
  for (let i = 0; i < navProps?.length || 0; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    refArray[i] = null
  }
  useEffect(() => {
    getNavsProps().then((data) => data && setNavProps(data));
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavHover = (event, title, index) => {
    setActiveNav(index)
    refArray[index] = event.currentTarget
    // if (title.toLowerCase() === "about") {
    //   setIsOpen(true);
    //   if (event?.currentTarget) setAboutAnchorEl(event.currentTarget);
    //   setLeaderAnchorEl(null);
    //   console.log(title);
    // } else if (title.toLowerCase() === "leadership") {
    //   setIsOpen(true);
    //   if (event?.currentTarget) setLeaderAnchorEl(event.currentTarget);
    //   setAboutAnchorEl(null);
    // } else {
    //   setAboutAnchorEl(null);
    //   setLeaderAnchorEl(null);
    //   setIsOpen(false);
    // }
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const styles = {
    headerContainer: {
      width: "100%",
      height: "100px",
      color: !isDark ? "black" : "white",
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: 5,
      flexwWrap: "wrap",
      opacity: "1",
      backgroundColor:
        scrollPosition > 20 ? (isDark ? "gray" : "white") : "transparent",
      // borderBottom: `1px solid ${scrollPosition > 30 ? '#388BC8' : 'white'}`,
      // borderRadius: scrollPosition > 30 ? '0px 0px 20px 20px' : '0px',
      boxShadow: scrollPosition > 20 ? "0px 7px 23px #0000001C" : "none",
    },
    navLinkContainer: {
      padding: "0px",
      display: "flex",
      width: "max-content",
      justifyContent: "flex-end",
      columnGap: "30px",
      // paddingRight: !(isMobile || isTablet) ? "130px" : "0px",
    },
    navLinkWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingBottom: "10px",
      fontSize: "18px",
      fontWeight: "500",
      textTransform: "uppercase",
      ":hover": {
        borderBottom: "3px solid",
        borderImage:
          "linear-gradient(to right, #FF6525 50%, transparent 50%) 100% 1",
      },
      a: {
        textDecoration: "none",
      },
    },
    navLogoContainer: {
      padding: "0px",
      display: "flex",
      width: "max-content",
      marginLeft: isMobile ? "0" : "-30px",
    },
    imageContainer: {
      height: "45px",
      width: isMobile ? "200px" : isTablet ? "240px" : "270px",
      display: "block",
      backgroundImage: `url(${require("../../assets/logo.jpg")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
      borderRadius: "16px",
    },
  };
  // const SelectBox = ({ subItems, title, url }) => {
  //   return (
  //     <Select
  //       displayEmpty
  //       value={""}
  //       open={isOpen}
  //       onOpen={() => setIsOpen(true)}
  //       onMouseEnter={() => {
  //         console.log(title);
  //         setIsOpen(true);
  //       }}
  //       onClose={() => setIsOpen(false)}
  //       // input={<OutlinedInput />}
  //       renderValue={(selected) => {
  //         if (selected.length === 0) {
  //           return (
  //             <Link
  //               sx={{
  //                 color:
  //                   isMobile || isTablet || scrollPosition > 30 ? "" : "black",
  //               }}
  //               href={url}
  //             >
  //               {title}
  //             </Link>
  //           );
  //         }

  //         return selected.join(", ");
  //       }}
  //       // MenuProps={MenuProp}
  //       inputProps={{ "aria-label": "Without label" }}
  //     >
  //       {subItems.map((items, idx) => (
  //         <MenuItem
  //           selected={false}
  //           sx={{
  //             borderTop: idx > 0 ? "1px solid" : "",
  //             borderImage:
  //               "linear-gradient(to right, #FF6525 50%, transparent 50%) 100% 1",
  //           }}
  //           component="a"
  //           key={items.title}
  //           href={items.url}
  //         >
  //           {items.title}
  //         </MenuItem>
  //       ))}
  //     </Select>
  //   );
  // };

  return (
    <Box
      sx={{
        ...styles.headerContainer,
      }}
      onMouseLeave={()=> setActiveNav(-1)}
    >
      <Container
        sx={{
          padding: isMobile || isTablet ? "0px 15px 0px 15px" : "0px 174px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={styles.navLogoContainer}>
          <Link href="/">
            <Box sx={styles.imageContainer} />
          </Link>
        </Box>
        <Box sx={styles.navLinkContainer}>
          {navProps && navProps.length && !(isMobile || isTablet) ? (
            navProps.map((ele, index) => (
              <Box
                key={`${ele.title}-${index}`}
                sx={{
                  ...styles.navLinkWrapper,
                  position:'relative',
                  borderBottom:
                    ele.title.toLowerCase() === `lets talk` ? "3px solid" : "",
                  borderImage:
                    ele.title.toLowerCase() === `lets talk`
                      ? "linear-gradient(to right, #FF6525 50%, transparent 50%) 100% 1"
                      : "",
                }}
              >
                {/* {ele.subNav.length ? (
                  <SelectBox
                    subItems={ele.subNav}
                    title={ele.title}
                    url={ele.url}
                  />
                ) : ( */}
                  <Link
                    sx={{
                      color:
                        isMobile || isTablet || scrollPosition > 30
                          ? ""
                          : "black",
                    }}
                    href={ele.url}
                    onMouseOver={(event) =>
                      ele.subNav ? handleNavHover(event, ele.title, index) : null
                    }
                  
                  >
                    {ele.title}
                  </Link>
                {/* )} */}

                <Box sx={{display: activeNav === index ? 'flex' :'none',flexDirection:'column',position:'absolute', top:'40px', left:'0', background:'white', boxShadow: "0px 7px 23px #0000001C", borderRadius:'0px 0px 0px 8px'}}>
                {ele.subNav?.map((option, idx) => (
                      <MenuItem
                        selected={false}
                        sx={{
                          borderTop: idx > 0 ? "1px solid" : "",
                          borderImage:
                            "linear-gradient(to right, #FF6525 50%, transparent 50%) 100% 1",
                            borderRadius:'8px'
                        }}
                        component="a"
                        key={option.title}
                        href={option.url}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                </Box>

                 {/* <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": `${ele.title}-sub-nav-menu`,
                    }}
                    anchorEl={refArray[index]}
                    open={isOpen && activeNav === index}
                    onClose={(e, reason) =>
                      reason === "backdropClick" && setIsOpen(false)
                    }
                    TransitionComponent={Fade}
                    sx={{
                      ".MuiPopover-paper": {
                        top: "60px !important",
                      },
                    }}
                  >
                    {ele.subNav?.map((option, idx) => (
                      <MenuItem
                        selected={false}
                        sx={{
                          borderTop: idx > 0 ? "1px solid" : "",
                          borderImage:
                            "linear-gradient(to right, #FF6525 50%, transparent 50%) 100% 1",
                        }}
                        component="a"
                        key={option.title}
                        href={option.url}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </Menu> */}

                {/* {ele.title.toLowerCase() === "about" && ele.subNav?.length ? (
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": `${ele.title}-sub-nav-menu`,
                    }}
                    anchorEl={anchorEl}
                    open={isOpen && anchorEl}
                    onClose={(e, reason) =>
                      reason === "backdropClick" && setIsOpen(false)
                    }
                    TransitionComponent={Fade}
                    sx={{
                      ".MuiPopover-paper": {
                        top: "60px !important",
                      },
                    }}
                  >
                    {ele.subNav?.map((option, idx) => (
                      <MenuItem
                        selected={false}
                        sx={{
                          borderTop: idx > 0 ? "1px solid" : "",
                          borderImage:
                            "linear-gradient(to right, #FF6525 50%, transparent 50%) 100% 1",
                        }}
                        component="a"
                        key={option.title}
                        href={option.url}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </Menu>
                ) : (
                  <></>
                )}

                {ele.title.toLowerCase() === "leadership" &&
                ele.subNav?.length ? (
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": `${ele.title}-sub-nav-menu`,
                    }}
                    anchorEl={leaderAnchorEl}
                    open={isOpen && leaderAnchorEl}
                    onClose={(e, reason) =>
                      reason === "backdropClick" && setIsOpen(false)
                    }
                    TransitionComponent={Fade}
                  >
                    {ele.subNav?.map((option) => (
                      <MenuItem
                        selected={false}
                        component="a"
                        key={option.title}
                        href={option.url}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </Menu>
                ) : (
                  <></>
                )} */}
              </Box>
            ))
          ) : (
            <SideNav navProps={navProps} />
          )}
        </Box>
      </Container>
    </Box>
  );
};
