import { Box, Button, CardMedia, Drawer, Link, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const SideNav=({ navProps })=> {
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:840px)');
    const styles ={
        navLogoContainer: {
            padding: '0px',
            display: 'block',
            width: isMobile || isTablet ? '40%' : '20%',
            transform: 'scale(1.5)'
          },
          navLogoImage: {
            width: '100%',
            height: '100%'
          }
    }
    return (
        <Box>
            <Button onClick={() => { setIsOpen(!isOpen) }}><MenuIcon /></Button>
            <Drawer anchor='top' onClose={() => setIsOpen(!isOpen)} open={isOpen}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '50vh', padding: '40px' }}>
                    <Box sx={{ display: 'flex', height: '100px',justifyContent: 'space-between' }}>
                        <Box sx={styles.navLogoContainer}>
                            <Link href='/'><CardMedia  image={require('../../assets/logo.jpg')} sx={styles.navLogoImage} /></Link>
                        </Box>
                        <Button onClick={() => { setIsOpen(!isOpen) }}><CloseIcon sx={{fontSize: isMobile ? '28px' :'50px'}}/></Button>
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'column',justifyContent:'space-between',padding:'20px 0px'}}>
                    {
                        navProps && navProps.length ? navProps.map((ele, idx) => (
                            <Box key={idx} sx={{ display:'flex',justifyContent:"center",width: '100%',padding:'20px 0px'}}>
                                <Link sx={{ textDecoration: 'none' }} href={ele.url}>
                                    <Typography  component={ isMobile ? 'h5' : 'h4'} variant={isMobile ? 'h5':'h4'}>
                                        {ele.title}
                                    </Typography>
                                </Link>
                            </Box>
                        )) : <></>
                    }
                    </Box>
                </Box>
            </Drawer>
        </Box>
    )
}

export default SideNav