import { Container, Button, Typography, useMediaQuery, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ContactForm } from '../components/ContactForm'
import { Box } from '@mui/system'
import Map from '../components/Maps/Map'

import IntroBanner from '../components/IntroBanner'
const Contact = () => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    // const isTablet = useMediaQuery('(max-width:840px)');

    useEffect(() => {
        const img = new Image();
        img.src = require('../assets/contact-intro.png');
    
        img.onload = () => {
          setImageLoaded(true);
        };
    
        // Optional: Handle image load error
        img.onerror = (error) => {
          console.error('Error loading image:', error);
        };
    
        // Clean up the event listeners when the component unmounts
        return () => {
          img.onload = null;
          img.onerror = null;
        };
      }, []);
    return (
        <>
            <IntroBanner height={'518px'} align={'left'} url={imageLoaded ? `url(${require('../assets/contact-intro.png')})` : ''}>
                <Box>
                    <Typography sx={{
                        color: '#004DD8',
                        letterSpacing: '0px',
                        paddingBottom: '40px',
                        width:isMobile ? '350px' :'433px',
                        fontWeight:'600'
                    }} variant='h3'>{'Ready to kickstart your tech career ?'}</Typography>
                    <Divider variant='fullWidth' sx={{ border: '2px solid #FF6525', width: '96px' }} />
                    <Button sx={{fontWeight:'400',marginTop:'22px', color: 'white', padding: '12px 20px', background: 'transparent linear-gradient(105deg, #004DD8 0%, #41BEEA 100%) 0% 0% no-repeat padding-box', boxShadow: '0px 7px 23px #0000001C' }}>{'Join us now!'}</Button>
                </Box>
            </IntroBanner>
            <Container sx={{ padding: '40px 0px' }}>
            <Box>
                <Box sx={{paddingBottom:'40px',}}>
                    <Typography sx={{width:'100%', color:'#848484', fontSize:'14px',letterSpacing: '5.04px',paddingLeft:isMobile ? '10px' : '0px'  }}>{'GOT A QUESTION ?'}</Typography>
                    <Typography sx={{width:'100%',color:'#004DD8', fontSize:'34px', fontWeight:'500',paddingLeft:isMobile ? '10px' : '0px' }}>{'We\'d love to hear from you'}</Typography>
                </Box>
                <ContactForm />
            </Box>
            </Container>
            <Box>
                <Container>
                    <Map />
                </Container>
            </Box>
        </>
    )
}

export default Contact