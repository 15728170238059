import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loader = () => {
  return (
    <Box sx={{width:'100vw', height:'100vh', position:'absolute', background:'rgba(0,0,0,0.5)', zIndex:'5',display:'flex', justifyContent:'center', alignItems:'center',left:0}}>
    <CircularProgress />
    </Box>
  )
}

export default Loader