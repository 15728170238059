import {
    Box,
    Button,
    Container,
    Divider,
    Link,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import IntroBanner from "../components/IntroBanner";
import { useParams } from "react-router-dom";
import { LEADERSHIP_PAGE_CONTENT } from "../content/pageStaticContent";


const Leadership = () => {
    const isMobile = useMediaQuery("(max-width:600px)");
    const isTablet = useMediaQuery("(max-width:840px)");
    const { slug } = useParams();
    const {ourMissions}= LEADERSHIP_PAGE_CONTENT
    // const ourMissions = [
    //     "Providing high-quality technology consulting services that help organizations make informed technology decisions and improve their IT systems and processes.",
    //     "Delivering innovative and practical technology solutions that support the unique needs and requirements of each client.",
    //     "Building long-term relationships with clients based on trust, transparency, and mutual success.",
    //     "Continuously improving and updating its consulting services to stay at the forefront of technology and meet the evolving needs of clients.",
    //     "Providing a supportive and rewarding work environment for its employees and fostering a culture of innovation and collaboration.",
    // ];

    useEffect(() => {
        if (slug) {
            setTimeout(() => {
                const id = slug;
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }, 0);
        }
    });
    const styles = {
        container: {
            padding: "0px 40px 0px 40px",
            height: "100%",
            width: "100%",
        },
        diversityImageContainer: {
            height: "264px",
            width: isMobile ? "100%" : isTablet ? "340px" : "550px",
            display: "block",
            backgroundImage: `url(${require("../assets/leadership-diversity.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            objectFit: "cover",
            borderRadius: "16px",
        },
        imageContainer2: {
            height: "441px",
            width: isMobile ? "100%" : isTablet ? "340px" : "550px",
            display: "block",
            backgroundImage: `url(${require("../assets/leadership-culture.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            objectFit: "cover",
            borderRadius: "16px",
        },
        imageContainer: {
            height: "540px",
            width: isMobile ? "auto" : isTablet ? "340px" : "480px",
            display: "block",
            backgroundImage: `url(${require("../assets/about-heading.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            objectFit: "cover",
            borderRadius: "16px",
        },
        ourMissionContainer: {
            height: isMobile? '1200px' :"634px",
            width: "100%",
            display: "block",
            backgroundImage: `url(${require("../assets/about-ourmission.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            objectFit: "cover",
            mixBlendMode: "luminosity",
            paddingBottom: "40px",
            borderRadius: "15px",
        },
    };

    // const ourTeam = [{ url: '../assets/demo-team-1.png', name: 'Name 1', occupation: 'xyz' }, { url: '../assets/demo-team-1.png', name: 'Name erty', occupation: 'xyz' }, { url: '../assets/demo-team-1.png', name: 'Name 1', occupation: 'xyz' }, { url: '../assets/demo-team-1.png', name: 'Name 1', occupation: 'xyz' }]

    return (
        <>
            <IntroBanner>
                <Typography
                    sx={{
                        color: "#004DD8",
                        letterSpacing: "0px",
                        paddingBottom: "40px",
                    }}
                    variant="h3"
                >
                    {"Leadership"}
                </Typography>
                <Divider
                    variant="fullWidth"
                    sx={{ border: "2px solid #FF6525", width: "96px" }}
                />
                <Typography sx={{ paddingTop: "22px", textAlign: "center" }}>
                    {
                        "My job is to help my clients ask better questions, my mentor taught me, ask a better question, get a better answer."
                    }
                </Typography>
                <Typography
                    sx={{
                        paddingTop: "22px",
                        fontSize: "18px",
                        color: "#000000",
                        fontWeight: "600",
                    }}
                >
                    {"- MIT DOSHI"}
                </Typography>
            </IntroBanner>
            <Container>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        justifyContent: "center",
                        gap: isMobile ? "40px" : isTablet ? "30px" : "119px",
                        height: "100%",
                        width: "100%",
                        paddingTop: "80px",
                        paddingBottom: "80px",
                    }}
                >
                    <Box>
                        <Box sx={styles.imageContainer} />
                    </Box>
                    <Box sx={{ textAlign: "left" }}>
                        <Typography
                            sx={{ color: "#848484", fontSize: "14px", paddingBottom: "5px" }}
                        >
                            {"Leadership"}
                        </Typography>
                        <Typography
                            sx={{ color: "#004DD8", fontSize: "34px", paddingBottom: "20px" }}
                        >
                            {"How we do it"}
                        </Typography>
                        <Divider
                            variant="fullWidth"
                            sx={{ border: "2px solid #FF6525", width: "96px" }}
                        />
                        <Typography
                            sx={{
                                color: "#606060",
                                fontSize: "16px",
                                width: isMobile ? "auto" : isTablet ? "344px" : "441px",
                                paddingTop: "24px",
                            }}
                        >
                            {
                                "Rediscover the way we lead and collaborate to empower people, build an inclusive culture, and drive long-term behavior change."
                            }
                        </Typography>
                    </Box>
                </Box>

                {/* our missions */}
                <Box id={"mission"} sx={styles.ourMissionContainer}>
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            padding: "60px 0px",
                        }}
                    >
                        <Box
                            sx={{
                                width: "max-content",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        color: "#848484",
                                        letterSpacing: "0px",
                                        fontSize: "14px",
                                        paddingBottom: "0px",
                                    }}
                                >
                                    {"Our"}
                                </Typography>
                                <Typography sx={{ color: "#004DD8", fontSize: "34px" }}>
                                    {"Mission"}
                                </Typography>
                            </Box>
                            <Box sx={{ paddingTop: "20px" }}>
                                <Typography
                                    sx={{
                                        color: "#606060",
                                        fontSize: "16px",
                                        width: "auto",
                                        maxWidth: isMobile
                                            ? "340px"
                                            : isTablet
                                                ? "700px"
                                                : "1027px",
                                    }}
                                >{`The mission of Newhatch is to help organizations leverage technology to achieve their goals and objectives. The specific mission of Newhatch can vary depending on the company's focus and the markets it serves, but some common elements of a mission statement for an IT consulting company might include`}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                                    width: "100%",
                                    paddingTop: "20px",
                                }}
                            >
                                {ourMissions.map((ele, idx) => (
                                    <Box
                                        key={`${idx}-${ele}`}
                                        sx={{
                                            display: "flex",
                                            gap: "18px",
                                            justifyContent: "flex-start",
                                            height: "100%",
                                            alignItems: "center",
                                            paddingBottom: isMobile ? "15px" : "0px",
                                        }}
                                    >
                                        <Typography sx={{ color: "#004DD8", fontSize: "73px" }}>
                                            {idx + 1}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                width: isMobile
                                                    ? "200px"
                                                    : isTablet
                                                        ? "255px"
                                                        : "344px",
                                                color: "#606060",
                                                fontSize: "16px",
                                            }}
                                        >
                                            {ele}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                
                {/* CULTURE */}
                <Box sx={{ position: "relative", height: isMobile ? 'auto' : "700px", width: "100%",margin:'80px 0px 0px 0px' }}>
                    {!isMobile ? <Box sx={styles.imageContainer2} /> : <></>}
                    <Box
                        sx={{
                            display: "flex",
                            width: isMobile ? '290px' : isTablet ? '450px' : '794px',
                            height: isMobile ? '100%' : "400px",
                            justifyContent: "center",
                            padding: "60px 35px",
                            position: isMobile ? 'relative' : 'absolute',
                            top: '20%',
                            left: isMobile ? '0' : '25%',
                            background: '#F2FCFF 0% 0% no-repeat padding-box',
                            borderRadius: '16px',

                        }}
                    >
                        <Box
                            sx={{
                                width: "max-content",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{ color: "#004DD8", fontSize: "34px", fontWeight: "500" }}
                                >
                                    {"CULTURE"}
                                </Typography>
                            </Box>
                            <Box sx={{ paddingTop: "20px" }}>
                                <Typography
                                    sx={{
                                        color: "#606060",
                                        fontSize: "16px",
                                        width: "auto",
                                        maxWidth: isMobile
                                            ? "340px"
                                            : isTablet
                                                ? "700px"
                                                : "1027px",
                                    }}
                                >{`The culture of an IT consulting company can vary widely depending on the company's size, management, and industry. However, in general, NewHatch often has a fast-paced, results-driven culture where employees are expected to work collaboratively, think critically, and be adaptable to change.`}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: "20px" }}>
                                <Typography
                                    sx={{
                                        color: "#606060",
                                        fontSize: "16px",
                                        width: "auto",
                                        maxWidth: isMobile
                                            ? "340px"
                                            : isTablet
                                                ? "700px"
                                                : "1027px",
                                    }}
                                >{`NewHatch consultants are often problem-solvers who work with clients to identify their technology needs and provide solutions. This requires strong communication and interpersonal skills, as well as the ability to work well under pressure and meet tight deadlines.`}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: "20px" }}>
                                <Typography
                                    sx={{
                                        color: "#606060",
                                        fontSize: "16px",
                                        width: "auto",
                                        maxWidth: isMobile
                                            ? "340px"
                                            : isTablet
                                                ? "700px"
                                                : "1027px",
                                    }}
                                >{`Employees are supported and encouraged to grow professionally and take on new challenges. There is often an emphasis on teamwork and collaboration, as well as open communication and transparency.`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* DIVERSITY */}
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        padding: "60px 0px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: 'flex', columnGap: '30px' }}>
                            {!isMobile ? <Box
                                sx={{
                                    width: isMobile ? "100%" : "max-content",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Box sx={styles.diversityImageContainer} />
                            </Box> : <></>}
                            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}>
                                <Typography
                                    sx={{ color: "#004DD8", fontSize: isMobile ? '22px' : "30px", fontWeight: "500" }}
                                >
                                    {"DIVERSITY/EQUITY/INCLUSION"}
                                </Typography>
                                <Divider
                                    variant="fullWidth"
                                    sx={{ border: "2px solid #FF6525", width: "96px" }}
                                />
                                <Typography
                                    sx={{
                                        color: "#606060",
                                        fontSize: "16px",
                                        width: isMobile ? '100%' : "490px",
                                        // maxWidth: isMobile ? "340px" : isTablet ? "700px" : "1027px",
                                    }}
                                >{'Our emergent'}<Typography sx={{ fontWeight: '600' }}>{'DIVERSITY/EQUITY/INCLUSION'}</Typography>{`(DEI) goals are established for progression and empower the Values we bring as a world-class technology services firm.  `}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ paddingTop: "20px" }}>
                            <Typography
                                sx={{
                                    color: "#606060",
                                    fontSize: "16px",
                                    width: "auto",
                                    maxWidth: isMobile ? "340px" : isTablet ? "700px" : "100%",
                                }}
                            >{`Our emergent Diversity, Equity, and Inclusion (DEI) goals are established for the progression and empowerment of the Values we bring as a world-class technology services firm.  By fostering DEI cultivation, we strive to inspire a deeper understanding of differing experiences, increase emotional intelligence, and create growth opportunities for our staff, and consultants, while positively impacting Newhatch and client business initiatives. `}</Typography>
                            <Typography
                                sx={{
                                    color: "#606060",
                                    fontSize: "16px",
                                    width: "auto",
                                    maxWidth: isMobile ? "340px" : isTablet ? "700px" : "1027px",
                                }}
                            >{`A culture of performance drives life at Newhatch. We hire top talent with the will to win, train that talent, and let them lead us to the future.  To do that well, we need a workforce that’s more representative of the candidates and clients we serve.`}</Typography>
                        </Box>

                    </Box>
                </Box>

                {/* OUR TEAM */}
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        padding: "60px 0px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    color: "#848484",
                                    letterSpacing: "8px",
                                    fontSize: "14px",
                                    paddingBottom: "0px",
                                }}
                            >
                                {"NEWHATCH"}
                            </Typography>
                            <Typography
                                sx={{ color: "#004DD8", fontSize: "34px", fontWeight: "500" }}
                            >
                                {"TEAM"}
                            </Typography>
                        </Box>
                        <Box sx={{ paddingTop: "20px" }}>
                            <Typography
                                sx={{
                                    color: "#606060",
                                    fontSize: "16px",
                                    width: "auto",
                                    maxWidth: isMobile ? "340px" : isTablet ? "700px" : "1027px",
                                }}
                            >{`I am satisfied with how my kindred colleagues answered the question. In the past, we have been dedicated to empowering a strong work culture for our representatives in general and our ongoing development has proven this point. From here on out, we will continue supporting DE&I approaches and building a much more impressive future for this organization.`}</Typography>
                        </Box>
                        <Box sx={{ paddingTop: "20px" }}>
                            <Typography
                                sx={{
                                    color: "#606060",
                                    fontSize: "16px",
                                    width: "auto",
                                    maxWidth: isMobile ? "340px" : isTablet ? "700px" : "1027px",
                                }}
                            >{`IRephrase We're committed to creating measurable, pragmatic objectives that are guideposts for our work. We also take on established DEI practices such as Monthly Preparing and Exceeding Objectives; Enrollment Process Changes; Segregation Punishments; Psychological Well-being Help; Execution Assessments; Motivating Oneself and Advancement Opportunities.`}</Typography>
                        </Box>
                        <Box sx={{ paddingTop: "20px" }}>
                            <Typography
                                sx={{
                                    color: "#606060",
                                    fontSize: "16px",
                                    width: "auto",
                                    maxWidth: isMobile ? "340px" : isTablet ? "700px" : "1027px",
                                }}
                            >{`We measure the consequences of our DEI drives through Representative maintenance, the organization's efficiency, and worker spirit and turnover.`}</Typography>
                        </Box>
                        {/* OUR TEAM PICS */}
                        {/* <Box sx={{ marginTop: '60px', display: 'grid', gridTemplateColumns: isMobile ? '1fr 1fr' : isTablet ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr' }} >
                            {
                                ourTeam?.map((ele, idx) => (<Box key={`${ele.name}-${idx}`} sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: 'max-content' }}>
                                    <Avatar
                                        alt={ele.name}
                                        src={ele.url}
                                        sx={{ width: isMobile ? 160 : isTablet ? 200 : 219, height: isMobile ? 160 : isTablet ? 200 : 219 }}
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        <Typography sx={{ textAlign: 'center', letterSpacing: '0', color: 'black', fontWeight: '600' }}>{ele.name}</Typography>
                                        <Typography sx={{ textAlign: 'center', letterSpacing: '0', color: '#606060', fontWeight: '200' }}>{ele.occupation}</Typography>
                                    </Box>
                                </Box>))
                            }
                        </Box> */}
                    </Box>
                </Box>

                <Box sx={styles.fullScreenBanner3}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "230px",
                            opacity: "0.77",
                            background:
                                "linear-gradient(106deg, #0659DA 20%, #3EB9E9 80%) 0% 0% no-repeat padding-box",
                            padding: "40px 0px",
                            borderRadius: "20px",
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: "center",
                                fontWeight: "900",
                                fontSize: "1.8em",
                                color: "white",
                            }}
                        >
                            {"Ready to kickstart your tech career ?"}
                        </Typography>
                        <Box sx={{ textAlign: "center", padding: "20px 0px" }}>
                            <Button
                                sx={{
                                    color: "#004DD8",
                                    background: "white",
                                    ":hover": {
                                        background: "white",
                                        outline: "2px solid #3EB9E9",
                                    },
                                }}
                                size="medium"
                            >
                                <Link
                                    href="/contact"
                                    sx={{
                                        textAlign: "center",
                                        fontWeight: "900",
                                        fontSize: "1.8em",
                                        textDecoration: "none",
                                    }}
                                >
                                    {"Join us now!"}
                                </Link>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>

            {/* <Box sx={{ width: '100%', height: '100%', background: '#F2FCFF', padding: '40px 0px 90px 0px' }}>
                <Container sx={{ width: '100%', height: '100%', }}>
                    <Box sx={{ width: '100%', display: 'flex', gap: isMobile? '0px':'20px', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <Box sx={{ font: 'normal normal normal 357px/280px Rosalia', height: 'max-content',color:'#FF6525', opacity:'0.17' }}>{'"'}</Box>
                        </Box>
                        <Box sx={{ paddingLeft: isMobile ? '30px':'0px', height: 'auto' }}>
                            <Typography sx={{ fontSize: '25px', color: '#004DD8', width: isMobile ? 'auto' : isTablet ? '400px' : '680px',fontWeight:'500' }}>{'My job is to help my clients ask better questions. My mentor taught me, `Ask a better question, get a better answer.'}</Typography>
                            <Typography sx={{ fontSize: '18px', color: '#000000',fontWeight:'600' }}>{'- MIT DOSHI'}</Typography>
                        </Box>
                    </Box>
                </Container>
            </Box> */}
        </>
    );
};

export default Leadership;
