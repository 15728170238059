import {
  Avatar,
  Box,
  CardMedia,
  Container,
  Divider,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { getNavsProps } from "../services/staticService";

export const Footer = () => {
  // const isSmallMobile = useMediaQuery('(max-width:376px)');
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");
  const [navProps, setNavProps] = useState([]);
  const styles = {
    footerMain: {
      position: "relative",
      // background: `#000000 center center/cover no-repeat`,
      color: "#000000",
      fontSize: "16px",
      maxWidth: "100%",
      height: "100%",
    },
    footerContent: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      padding: "50px 0px",
      height: "100%",
      gap:isTablet? '20px' :'10px',
      justifyContent: "space-between",
    },
    footerBottomNav: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "15px 0px",
      width: "100%",
    },
    navLinkWrapper: {
      color: "gray",
      textDecoration: "none",
      fontWeight: "500",
      ":hover": {
        color: "black",
        borderBottom: "2px solid",
        borderImage:
          "linear-gradient(to right, #FF6525 50%, transparent 50%) 100% 1",
        paddingBottom: "5px",
      },
    },
  };

  const subNavs = [
    { title: "Our approach", url: "/about/approach" },
    { title: "Our process", url: "/about/process" },
    { title: "Our Mission", url: "/leadership/mission" },
    { title: "Faqs", url: "/service/faqs" },
  ];
  useEffect(() => {
    getNavsProps().then((data) => data && setNavProps(data));
  }, []);
  return (
    <Box sx={styles.footerMain}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box sx={styles.footerContent}>
          <Box>
            <Box
              sx={{
                marginLeft: isMobile ? "0px" : "-31px",
                marginTop: isMobile ? "0px" : "-26px",
              }}
            >
              <Link href="/" sx={{ textDecoration: "none", padding: "0px" }}>
                <CardMedia
                  image={require("../assets/logo.jpg")}
                  sx={{ width:isMobile?'100%' : "300px", height: "90px" }}
                />
              </Link>
            </Box>
            <Typography
              sx={{
                color: "#919191",
                width: isMobile? '100%':"320px",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              {
                "NewHatch is a cutting-edge IT consulting company that offers organizations to help them make informed technology decisions and improve their IT systems and processes"
              }
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "row" : "column",
              gap: "15px",
              width:isMobile?'100%' : isTablet ? "90%" : "max-content",
              padding: isMobile ? "15px 0px 10px 0px" : "0px",
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            {navProps.map((ele, idx) => (
              <Box
                key={`${idx}-${ele.title}`}
                sx={isTablet ? { ...{ textAlign: "right" } } : {}}
              >
                <Link sx={styles.navLinkWrapper} href={ele.url}>
                  {ele.title}
                </Link>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: isMobile ? "none" : "flex",
              flexDirection: isMobile ? "row" : "column",
              gap: "15px",
              width: isTablet ? "90%" : "max-content",
            }}
          >
            {subNavs.map((ele, idx) => (
              <Box
                key={`${idx}-${ele.title}`}
                sx={isTablet ? { ...{ textAlign: "right" } } : {}}
              >
                <Link sx={styles.navLinkWrapper} href={ele.url}>
                  {ele.title}
                </Link>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "8px",
              paddingTop: isMobile ? "20px" : "0px",
            }}
          >
            <Typography
              sx={{
                color: "#004DD8",
                fontSize: "20px",
                fontWeight: "500",
                width: "100%",
                lineHeight: "15px",
              }}
            >
              {"Corporate Headquarters"}
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "column", rowGap: "3px" }}
            >
              <Typography sx={{ fontSize: "16px", cursor: "pointer" }}>
                {/* {"Address:"} */}
                <Typography
                  component={"a"}
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    color: "gray",
                    // paddingLeft: "10px",
                  }}
                  href="tel:+1(440)749-9138"
                >
                  {"25 Main Street Suite 200, Westlake, OH 44145."}
                </Typography>
              </Typography>
              <Typography sx={{ fontSize: "16px", cursor: "pointer" }}>
                {/* {"Contact:"} */}
                <Typography
                  component={"a"}
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    color: "gray",
                    // paddingLeft: "10px",
                  }}
                  href="tel:+1(440)749-9138"
                >
                  {"+1 (440) 749-9138"}
                </Typography>
              </Typography>
              <Typography sx={{ fontSize: "16px", cursor: "pointer" }}>
                {/* {"Email:"} */}
                <Typography
                  component={"a"}
                  sx={{
                    textDecoration: "none",
                    fontSize: "12px",
                    color: "gray",
                    // paddingLeft: "10px",
                  }}
                  href="mailto:hr@newhatchcorp.com"
                >
                  {"hr@newhatchcorp.com"}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* copyright divider */}
        <Divider
          variant="fullWidth"
          sx={{ borderColor: "#FF6525", opacity: "0.17" }}
        />
        <Box sx={styles.footerBottomNav}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ color: "#848484", fontSize: "14px", fontWeight: "500" }}
            >{`Copyright © 2020-${new Date().getFullYear()} newhatch`}</Typography>
          </Box>
        </Box>
      </Container>
      {/* Social links */}
      <Box
        sx={{
          position: "fixed",
          zIndex: "4",
          height: "100%",
          right: isMobile ? "5px" : "20px",
          top: "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "15px",
            justifyContent:
              isTablet && !isMobile ? "flex-end" : "space-between",
            width: isTablet && !isMobile ? "90%" : "max-content",
            ".MuiSvgIcon-root:hover": {
              cursor: "pointer",
              transform: "scale(1.3)",
            },
          }}
        >
          <Link
            sx={{ color: "inherit", textAlign: "center" }}
            href={"https://www.facebook.com/NewHatchCorp?mibextid=LQQJ4d"}
          >
            <Avatar
              sx={{
                bgcolor: "white",
                color: "#FF6525",
                boxShadow: "0px 7px 23px #0000001C",
              }}
            >
              <FacebookOutlinedIcon />
            </Avatar>
          </Link>
          <Link
            sx={{ color: "inherit" }}
            href={"https://instagram.com/newhatchcorp?igshid=YmMyMTA2M2Y="}
          >
            <Avatar
              sx={{
                bgcolor: "white",
                color: "#004DD8",
                boxShadow: "0px 7px 23px #0000001C",
              }}
            >
              <InstagramIcon />
            </Avatar>
          </Link>
          <Link
            sx={{ color: "inherit" }}
            href={"https://www.linkedin.com/company/newhatch/"}
          >
            <Avatar
              sx={{
                bgcolor: "white",
                color: "#004DD8",
                boxShadow: "0px 7px 23px #0000001C",
              }}
            >
              <LinkedInIcon />
            </Avatar>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
