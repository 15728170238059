import { Container, Grid } from '@mui/material'
import React from 'react'

const Dashboard = () => {
  return (
    <div style={{ display: 'flex', height: '89vh' }}>
      {/* <CssBaseline /> */}
      <Container>
        {/* Main content */}
        <Grid container>
          <Grid item xs={12}>
            {/* Admin Dashboard */}
            {/* Your content here */}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Dashboard