import React, { useState, useEffect } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import {
    Box,
    Card,
    TextField,
    Rating,
    Typography,
    useMediaQuery,
    Button,
} from "@mui/material";
import "particles.js";
import { bounceBallParticleConfig } from "../particleConfigs/bounceBallParticle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { postFeedback } from "../utils/admin";
const Feedback = () => {
    const isMobile = useMediaQuery("(max-width:600px)");
    const isTablet = useMediaQuery("(max-width:840px)");
    const [fullName, setfullName] = useState("");
    const [designation, setdesignation] = useState("");
    const [rating, setrating] = useState();
    const [feedbackMessage, setfeedbackMessage] = useState("");
    const [isSuccess, setisSuccess] = useState(false);
    useEffect(() => {
        window.particlesJS("particles-js", bounceBallParticleConfig);
    }, []);

    const handleOnSentFeedBack = () => {
        const response = postFeedback({
            fullName,
            designation,
            rating,
            message: feedbackMessage,
            imageUrl: "",
        });
        if (response) {
            setisSuccess(true);
        }
    };
    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <div
                id="particles-js"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: -20,
                    perspective: "20px",
                }}
            ></div>
            {!isSuccess && (
                <Typography
                    sx={{ fontWeight: "600", color: "#2F59A6" }}
                    variant="h4"
                    gutterBottom
                >
                    Feedback
                </Typography>
            )}
            <Card
                sx={{
                    borderRadius: "12px",
                    minWidth: isMobile ? "100%" : isTablet ? "80%" : "600px",
                    border: "1px solid #388BC8",
                }}
            >
                {isSuccess ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            padding: "40px 0px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: "400",
                                wordSpacing: "4px",
                                letterSpacing: "4px",
                            }}
                        >
                            Thank you for your feedback.
                        </Typography>
                        <Typography component={"a"} href="/">
                            Go to Home
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "15px",
                            padding: "15px 30px",
                        }}
                    >
                        <TextField
                            autoComplete="false"
                            aria-label="Full Name"
                            label="Full Name"
                            type={"text"}
                            value={fullName}
                            onChange={(e) => {
                                setfullName(e.target.value || "");
                            }}
                            required={true}
                            sx={{ width: "100%" }}
                        />
                        <TextField
                            autoComplete="false"
                            aria-label="Designation"
                            label="Designation"
                            type={"text"}
                            value={designation}
                            onChange={(e) => {
                                setdesignation(e.target.value || "");
                            }}
                            required={true}
                            sx={{ width: "100%" }}
                        />
                        <Box sx={{ display: "flex", gap: "10px" }}>
                            <Typography>Rate us:</Typography>
                            <Rating
                                name="simple-controlled"
                                value={rating}
                                onChange={(event, newValue) => {
                                    setrating(newValue);
                                    console.log("newValue", newValue);
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography>Message:</Typography>
                            <TextareaAutosize
                                aria-label="feedback message"
                                label="Message"
                                placeholder="message"
                                minRows={10}
                                aria-multiline
                                value={feedbackMessage}
                                onChange={(e) => {
                                    setfeedbackMessage(e.target.value || "");
                                }}
                                sx={{ width: "100%", minWidth: "700px" }}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "100%",
                            }}
                        >
                            <Button variant="outlined" onClick={handleOnSentFeedBack}>
                                {"send"}
                                <ArrowForwardIosIcon />
                            </Button>
                        </Box>
                    </Box>
                )}
            </Card>
        </Box>
    );
};

export default Feedback;
