import React, { useEffect, useState } from "react";
import IntroBanner from "../components/IntroBanner";
import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  Typography,
  useMediaQuery,
  List,
  ListItemIcon,
  ListItem,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useParams } from "react-router-dom";
import { SERVICES_PAGE_CONTENT } from "../content/pageStaticContent";

export const ServicesDetail = () => {
  const { slug } = useParams();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");
  const { ourServices } = SERVICES_PAGE_CONTENT;
  const [selectedServices, setselectedServices] = useState(ourServices[0]);
  useEffect(() => {
    setselectedServices(
      ourServices.find((ele) => ele.id.toLowerCase() === slug)
    );
  }, [ourServices, slug]);

  const styles = {
    fullScreenBanner1: {
      display: "flex",
      backgroundAttachment: "fixed",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "center center/cover no-repeat",
      backgroundSize: "100% 100%",
      color: "#fff",
      margin: " 0 auto",
      position: "relative",
      maxWidth: "100%",
      height: "95vh",
      background: "linear-gradient(to right top,#FFFFFF, #388BC8)",
    },
    fullScreenBanner2: {
      display: "flex",
      backgroundAttachment: "fixed",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "center center/cover no-repeat",
      backgroundSize: "100% 100%",
      color: "#fff",
      margin: " 0 auto",
      position: "relative",
      maxWidth: "100%",
      height: "10vh",
      paddingTop: "40px",
    },
    fullScreenBanner3: {
      minHeight: "200px",
      height: "100%",
      width: "100%",
      background: `url(${require("../assets/intro-banner.png")})`,
      backgroundSize: "cover",
      objectFit: "cover",
      mixBlendMode: "color-burn",
      borderRadius: "15px",
    },
    imageContainer: {
      height: "397px",
      width: isMobile ? "100%" : isTablet ? "340px" : "437px",
      display: "block",
      backgroundImage: `url(${require("../assets/about-heading.png")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
      borderRadius: "12px",
    },
    imageContainerOverlapp: {
      height: isMobile ? "400px" : "257px",
      width: isMobile ? "360px" : isTablet ? "300px" : "283px",
      display: "block",
      backgroundImage: `url(${require("../assets/service-intro2.png")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
      position: isMobile ? "relative" : "absolute",
      bottom: "0",
      right: "0",
      borderRadius: "12px",
    },
  };
  return (
    <>
      <IntroBanner>
        <Typography
          sx={{
            color: "#004DD8",
            letterSpacing: "0px",
            paddingBottom: "40px",
          }}
          variant="h3"
        >
          {selectedServices.title}
        </Typography>
        <Divider
          variant="fullWidth"
          sx={{ border: "2px solid #FF6525", width: "96px" }}
        />
        <Typography
          sx={{ paddingTop: "22px", width: "100%", textAlign: "center" }}
        >
          {selectedServices.desciption}
        </Typography>
      </IntroBanner>

      <Container sx={{ padding: "60px 0px" }}>
        {/* <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', padding: '20px 0px 40px 0px' }}>
                    <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '14px', color: '#848484', letterSpacing: '5.04px' }}>{'SERVICES'}</Typography>
                    <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '34px', color: '#004DD8' }}>{'Explore our varied areas of expertise'}</Typography>
                </Box> */}
        <Box
          sx={{
            width: "100%",
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: isMobile
              ? "1fr"
              : isTablet
              ? "1fr 1fr"
              : "1fr 1fr 1fr",
            gap: "30px",
          }}
        >
          {selectedServices.list.map((ele, idx) => {
            const headingTitle = ele.split(":")[0] || "";
            const content = ele.split(":")?.[1]?.trim() || "";
            const regex = /\[[^\]]+\]/; // Matches any text enclosed within square brackets
            const containsArrayPattern = regex.test(content);

            const contentLists = containsArrayPattern
              ? JSON.parse(content)
              : [];

            return (
              <Box
                key={`${ele.id}-${idx}`}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "380px",
                  justifyContent: "space-between",
                  padding: isMobile ? "20px" : "30px",
                  borderRadius: "18px",
                  boxShadow: "0px 7px 23px #0000001C",
                  ...selectedServices.sx,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#004DD8",
                      fontSize: "20px",
                      textAlign: "left",
                      minWidth: isMobile ? "100%" : "256px",
                      paddingBottom: "20px",
                      height: isMobile ? "max-content" : "auto",
                    }}
                  >
                    {headingTitle}
                  </Typography>
                  <Divider
                    variant="fullWidth"
                    sx={{ border: "2px solid #FF6525", width: "96px" }}
                  />
                  {contentLists?.length ? (
                    <List sx={{alignItems:'center',gap:'15px',display:'flex',flexDirection:'column',marginTop:'20px'}}>
                      {contentLists.map((item, index) => (
                        <ListItem sx={{ padding: 0, gap: "0px",alignItems:'center',height:'auto' }} key={index}>
                          <ListItemIcon>
                            <FiberManualRecordIcon />
                          </ListItemIcon>
                          <Typography
                            sx={{
                              color: "",
                              fontSize: "",
                              textAlign: "left",
                              width: isTablet ? "270px" : "309px",
                            }}
                          >
                            {item}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography
                      sx={{
                        color: "",
                        fontSize: "",
                        textAlign: "left",
                        width: isTablet ? "270px" : "309px",
                        paddingTop: "26px",
                      }}
                    >
                      {`${content?.charAt(0)?.toUpperCase()}${content.slice(
                        1
                      )}`}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Container>
      <Container>
        <Box sx={styles.fullScreenBanner3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "230px",
              opacity: "0.77",
              background:
                "linear-gradient(106deg, #0659DA 20%, #3EB9E9 80%) 0% 0% no-repeat padding-box",
              padding: "40px 0px",
              borderRadius: "20px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "900",
                fontSize: "1.8em",
                color: "white",
              }}
            >
              {"Ready to kickstart your tech career ?"}
            </Typography>
            <Box sx={{ textAlign: "center", padding: "20px 0px" }}>
              <Button
                sx={{
                  color: "#004DD8",
                  background: "white",
                  ":hover": {
                    background: "white",
                    outline: "2px solid #3EB9E9",
                  },
                }}
                size="medium"
              >
                <Link
                  href="/contact"
                  sx={{
                    textAlign: "center",
                    fontWeight: "900",
                    fontSize: "1.8em",
                    textDecoration: "none",
                  }}
                >
                  {"Join us now!"}
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};
