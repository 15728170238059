import { AppBar, Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import SideNav from "./SideNav";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { Logout } from "@mui/icons-material";
import { useState } from "react";
import { setUser } from "../../redux/auth/authActions";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";



function AdminHeader() {
  const dispatch = useDispatch();
  // const history = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then((data) => {
        // Sign-out successful.
        dispatch(setUser(null));
        sessionStorage.setItem('user', null)
        // console.log('User signed out.', data);
        window.location.reload()
      })
      .catch((error) => {
        // An error happened.
        console.error('Error signing out:', error);
      });
  }


  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', columnGap: '40px' }}>
            <Box sx={{ display: 'flex', columnGap: '20px', justifyContent: 'flex-start',alignItems:'center' }}>
              <SideNav />
              <Typography sx={{ marginLeft: '240px' }} variant="h6">{'Admin Dashboard'}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

    </AppBar>
  );
}

export default AdminHeader;