// src/components/Login.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
// import { Google } from '@mui/icons-material';
import { setUser } from '../../redux/auth/authActions';
import ParticleBackground from '../../particles/ParticleBackground';
// import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import Loader from '../../components/Loader';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const SignIn = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    // const { signIn } = useAuth()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setisLoading] = useState(false)

    // const handleGoogleSignIn = async (e) => {
    //     try {
    //         e?.preventDefault()
    //         const response = await signIn();
    //         const accessToken = await response.user.getIdToken();
    //         sessionStorage.setItem('user', JSON.stringify({ name: response.user.displayName, email: response.user.displayName, profilePic: response.user.photoURL, authToken: accessToken }))
    //         dispatch(setUser({ name: response.user.displayName, email: response.user.email, profilePic: response.user.photoURL, authToken: accessToken }));
    //         history('/admin');
    //         window.location.reload()
    //     } catch (error) {
    //         console.error('Google Sign-In Error:', error);
    //     }
    // };

    const handleLogin = async () => {
        const auth = getAuth();

        try {
            const response = await signInWithEmailAndPassword(auth, email, password);
            setisLoading(true)
            //   const user = userCredential.user;
            const idToken = await response.user.getIdToken();
            if (response.user.email.includes('admin@newhatch')) {
                sessionStorage.setItem('user', JSON.stringify({ name: response.user.displayName || response.user.email, email: response.user.email, profilePic: response.user.photoURL, authToken: idToken }))
                dispatch(setUser({ name: response.user.displayName || response.user.email, email: response.user.email, profilePic: response.user.photoURL, authToken: idToken }));
                history('/admin');
                window.location.reload()
            } else {
                dispatch(setUser(undefined));
                window.location.reload()
            }
            setisLoading(false)
        } catch (error) {
            setisLoading(false)
            console.error('Login Error:', error);
        }
    };
    const [showPassword, setShowPassword] = useState(false);
    if(isLoading){
        return <Loader />
      }


    return (
        <Box sx={{
            position: 'relative',
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
        }}>
            <ParticleBackground />
            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: '5', top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '320px',
                height: '350px',
                boxShadow: '0 0 10px #2F59A6',
                background: 'rgba(255, 255, 255, 0.3)',
                backdropFilter: 'blur(4px)',
                borderRadius: '8px',
                rowGap: '40px',
                padding: '12px 20px'
            }}>
                {/* <Box sx={{display:'flex', columnGap:'10px', alignItems:'center'}}> */}
                <Typography sx={{ fontWeight: '800', fontSize: '18px', textTransform: 'uppercase' }}>{'Admin Login'} </Typography>
                {/* <VpnKeyIcon sx={{ fontSize: '28px', fontWeight: 'bold' }} /> */}
                {/* </Box> */}
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                  
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <TextField
                     type={showPassword ? 'text' : 'password'}
                    label="Password"
                    name="password"
                    value={password}
                    sx={{width:'200px'}}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton sx={{ background: '#E7F0FE' }} onClick={() => setShowPassword(!showPassword)} edge="end">
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
        ),
      }}

                />
                <Button sx={{ fontWeight: '600', ":hover": { background: '' } }} variant='outlined' onClick={handleLogin}>Login</Button>
            </Box>
        </Box>

    );
}

export default SignIn;
