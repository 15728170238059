
export const setUser = (user) => ({
    type: 'SET_USER',
    payload: user,
  });
  
  export const signOut = () => ({
    type: 'SIGN_OUT',
  });
  
  const initialState = {
    user: null,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_USER':
        return { ...state, user: action.payload };
      case 'SIGN_OUT':
        return { ...state, user: null };
      default:
        return state;
    }
  };
  
  export default authReducer;
  