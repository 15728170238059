import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Home } from "./pages/Home";
import { ThemeProvider, createTheme } from "@mui/material";
import { Footer } from "./components/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
// import Career from './pages/Career';
import { Box } from "@mui/system";
import { Header } from "./components/Header/Header";
import Service from "./pages/Service";
import Leadership from "./pages/Leadership";
import { Helmet } from "react-helmet";
import { ServicesDetail } from "./pages/ServicesDetail";
// import { useSelector } from "react-redux";
import Admin from "./Admin/Admin";
import SignIn from "./Admin/components/Signin";
import AdminHeader from "./Admin/components/AdminHeader";
import { useCallback } from "react";
import AdminFooter from "./Admin/components/AdminFooter";
// import AddJobs from "./Admin/components/AddJobs";
// import ViewJobs from "./Admin/components/ViewJobs";
import Career from "./pages/Career";
// import PostJobs from "./Admin/pages/PostJobs";
// import ViewJobs from "./Admin/pages/ViewJobs";
import PageNotFound from "./pages/PageNotFound";
import JobDescription from "./pages/JobDescription";
import { AuthProvider } from "./contexts/AuthContext";
import Feedback from "./pages/Feedback";
// import PrivateRoute from "./Admin/components/PrivateRoute";


// const AppLayout = ({ children }) => (
//   <div>
//     <Header />
//     {children}
//     <Footer />
//   </div>
// );
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#495579",
      },
      secondary: {
        main: "#FFFBEB",
      },
    },
  });
  // const {currentUser } = useAuth()
  const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : '';

  const renderHeader = useCallback(() => {
    const isAdmin = user && user.name && window.location.href.includes('admin');
    const isLogin = window.location.href.includes('login');
    if (isLogin) {
      return <></>
    }
    if (!isAdmin) {
      return (<Header />)
    }
    return <AdminHeader />
  }, [user])


  // console.log(currentUser)
  const renderFooter = useCallback(() => {
    const isAdmin = user && user.name && window.location.href.includes('admin');
    const isLogin = window.location.href.includes('login');
    if (isLogin) {
      return <></>
    }
    if (!isAdmin) {
      return (<Footer />)
    }
    return <AdminFooter />
  }, [user])

  // const aminPanel = () => (
  //   <>
  //     <Route path="/admin" element={<Admin user={user} />} />
  //     <Route exact path="/admin/postJob" element={<PostJobs />} />
  //     <Route exact path="/admin/viewJob" element={<ViewJobs />} />
  //   </>
  // )


  if (user && user.name) {
    return <Admin />
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "block",
          padding: "0px",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {renderHeader()}
        <Helmet>
          <title>Newhatch LLC - IT Service & Consulting</title>
          <meta
            name="description"
            content="Newhatch LLC - IT Service & Consulting"
          />
          <meta
            name="keywords"
            content="Newhatch LLC - IT Service & Consulting"
          />
          <meta name="keywords" content="Newhatch LLC" />
          <meta name="keywords" content="IT Service & Consulting clevland" />
          <meta name="keywords" content="Newhatch" />
          <meta name="keywords" content="newhatchcorp" />
        </Helmet>
        <Box
          sx={{
            display: "block",
            padding: "0px",
            width: "100%",
            height: "100%",
            minHeight: '74vh',
            overflow: "hidden",
          }}
        >
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/about/:slug" element={<About />} />
                <Route exact path="/service" element={<Service />} />
                <Route exact path="/career" element={<Career />} />
                <Route exact path="/career/:slug" element={<JobDescription />} />
                <Route exact path="/service/:slug" element={<Service />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/leadership" element={<Leadership />} />
                <Route exact path="/leadership/:slug" element={<Leadership />} />
                <Route exact path="/detail/:slug" element={<ServicesDetail />} />
                <Route exact path="/feedback" element={<Feedback />} />

                <Route exact path="/admin-login" element={<SignIn />} />

                {/* <Route element={<PrivateRoute />}>
                  <Route exact path="/admin" element={<Admin user={user} />} />
                  <Route exact path="/admin/postJob" element={<PostJobs />} />
                  <Route exact path="/admin/viewJob" element={<ViewJobs />} />
                </Route> */}


                {/* {user && user.name ? aminPanel() : <Route path="/admin-login" element={<SignIn />} />} */}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </AuthProvider>
          </BrowserRouter>

        </Box>

        {renderFooter()}
      </Box>
    </ThemeProvider>
  );
}

export default App;
