import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogTitle,
    Grid,
    Snackbar,
    Step,
    StepButton,
    Stepper,
    TextField,
    Typography,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { db } from "../firebase";
import CloseIcon from "@mui/icons-material/Close";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    query,
    serverTimestamp,
    setDoc,
    where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Loader from "./Loader";

const ModalFormStepper = ({ open, handleClose, title = "", jobDetails }) => {
    const steps = ["Profile Info", "Upload Resume", "Review & Submit"];
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        resume: "",
    });
    const formData = useRef({});
    const initialFormState = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      };
    
      const [formState, setFormState] = useState(initialFormState);

    const totalSteps = () => {
        return steps.length;
    };

    const [resume, setResume] = useState(null);

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newCompleted = completed;
        if (activeStep < 2) newCompleted[activeStep] = true;
        setCompleted(newCompleted);

        const validationErrors = { ...errors };
        if (activeStep === 0) {
            // firstname
            if (!formData.current.firstName) {
                validationErrors.firstName = "First Name is required";
            }else{
                validationErrors.firstName = "";
            }

            // lastname
            if (!formData.current.lastName) {
                validationErrors.lastName = "Last Name is required";
            }else{
                validationErrors.lastName = "";
            }

            // email
            if (!formData.current.email) {
                validationErrors.email = "Email is required";
            }else{
                validationErrors.email = "";
            }

            if (!formData.current.phone) {
                validationErrors.phone = "Contact Number is required";
            }else{
                validationErrors.phone = "";
            }
        }
        if (!resume && activeStep === 1) {
            setActiveStep(1)
            validationErrors.resume = "Resume is required";
        }

        const hasValidationErrors = Object.values(validationErrors).some(
            (error) => error !== ""
        );

        // console.log(validationErrors)

        setErrors({...validationErrors});

        if (hasValidationErrors) {
            return;
        } 


        const newActiveStep =
        isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
            setActiveStep(newActiveStep);

    };

    const handleBack = () => {
        const newCompleted = completed;
        newCompleted[activeStep - 1] = false;
        setCompleted(newCompleted);

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    // const handleComplete = () => {
    //     const newCompleted = completed;
    //     newCompleted[activeStep] = true;
    //     setCompleted(newCompleted);
    //     handleNext();
    // };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        formData.current[name] = value;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        
        if (name === 'email') {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (!emailPattern.test(value)) {
              setErrors({
                ...errors,
                [name]: 'Invalid email format',
              });
            } else {
              setErrors({
                ...errors,
                [name]: '',
              });
            }
          }
    };

    const handleResumeUpload = async (e) => {
        const uploadedFile = e.target.files[0];
        if (!uploadedFile?.type.includes("pdf")) {
            setIsError(true);
            setIsSnackBarOpen(true);
            setMessage("Only pdf types are allowed");
        } else {
            setResume(uploadedFile);
        }
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsSnackBarOpen(false);
    };

    const handleSubmit = async () => {
        try {
            const queryGetContact = formData.current?.email
                ? query(
                    collection(db, "contact"),
                    where("email", "==", formData.current.email)
                )
                : "";
            let docRef = queryGetContact ? await getDocs(queryGetContact) : "";
            if (!docRef && formData.current?.email) {
                docRef = await addDoc(collection(db, "contact"), {
                    ...formData.current,
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                });
            }

            const docIds =
                docRef && docRef?.docs?.length ? docRef.docs.map((doc) => doc.id) : "";

            if (!resume) {
                return;
            }

            setIsLoading(true);
            const storage = getStorage();
            const storageRef = ref(
                storage,
                `resumeUploads/${Date.now()}_${resume.name}`
            );
            // const storageRef = storage.ref(`resumeUploads/${Date.now()}_${resume.name}`);

            const uploading = await uploadBytes(storageRef, resume);
            // await storageRef.put(resume);

            const fileUrl = uploading ? await getDownloadURL(storageRef) : "";
            const documentRef =
                docIds?.length && fileUrl ? doc(db, "contact", docIds[0]) : undefined;

            if (documentRef) {
                await setDoc(
                    documentRef,
                    {
                        fileUrl: fileUrl,
                        jobEnquiryId: jobDetails?.id ? jobDetails.id : "",
                        updatedAt: serverTimestamp(),
                    },
                    { merge: true }
                );
            }
            setIsLoading(false);
            handleReset();
            handleClose();
            setIsSnackBarOpen(true);
            setFormState(initialFormState)
            setMessage("Application submitted successfully.");
        } catch (error) {
            // console.error('Error uploading file and updating Firestore:', error);
            setIsLoading(false);
            setIsError(true);
            setIsSnackBarOpen(true);
            setMessage("Error submitting application.");
        }
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;

        // Handle the first dropped file (you can modify this logic)
        if (droppedFiles.length > 0) {
            // const firstDroppedFile = droppedFiles[0];
            handleResumeUpload({ target: { files: { ...droppedFiles } } });
        }
    };

    const getStepContent = useCallback(() => {
        // const formProps = {...formData.current};
        const stepErrors = errors; 
        switch (activeStep) {
            case 0:
                return (
                    <Grid sx={{ width: "100%" }} container spacing={5}>
                        <Grid item sx={6} md={6} lg={6}>
                            <TextField
                                label="First Name"
                                name="firstName"
                                value={formState.firstName}
                                onChange={handleFormChange}
                                required
                                error={Boolean(stepErrors.firstName)}
                                helperText={stepErrors.firstName}
                            />
                        </Grid>
                        <Grid item sx={6} md={6} lg={6}>
                            <TextField
                                label="Last Name"
                                name="lastName"
                                value={formState?.lastName}
                                onChange={handleFormChange}
                                required
                                error={Boolean(stepErrors.lastName)}
                                helperText={stepErrors.lastName}
                            />
                        </Grid>
                        <Grid item sx={6} md={6} lg={6}>
                            <TextField
                                label="Email"
                                name="email"
                                value={formState?.email}
                                onChange={handleFormChange}
                                required
                                error={Boolean(stepErrors.email)}
                                helperText={stepErrors.email}
                            />
                        </Grid>
                        <Grid item sx={6} md={6} lg={6}>
                            <TextField
                                label="Contact Number"
                                name="phone"
                                type="phone"
                                value={formState?.phone}
                                onChange={handleFormChange}
                                required
                                error={Boolean(stepErrors.phone)}
                                helperText={stepErrors.phone}
                            />
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={5} sx={{ minWidth: "500px" }}>
                        <Grid item xs={12}>
                            <Typography>{"Upload the Resume"}</Typography>
                        </Grid>
                        {resume ? (
                            <Grid item sx={6} md={6} lg={6}>
                               
                                    <Box
                                        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                                    >
                                        <Box
                                            sx={{
                                                padding: "5px 10px",
                                                background: "red",
                                                borderRadius: "6px 0px 0px 6px",
                                                color: "white",
                                            }}
                                        >{`.${resume?.name?.split(".").length > 1
                                                ? resume.name.split(".")[1]
                                                : "pdf"
                                            }`}</Box>
                                        <Typography>{resume?.name}</Typography>
                                    </Box>
                              
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "80px",
                                        border: "1px dashed lightgray",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                                        {`Upload a File or, drop (.pdf)`}{" "}
                                        <span style={{ color: "blue" }}>here</span>
                                    </label>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        accept=".pdf, .doc, .docx"
                                        onChange={handleResumeUpload}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                        }}
                                        onDrop={handleFileDrop}
                                        style={{ display: "none" }}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                );
            case 2:
                return (
                    <Grid container spacing={5} sx={{ minWidth: "500px" }}>
                        {formState && Object.keys(formState).length > 1 ? (
                            Object.keys(formState).map((ele) => (
                                <Grid item sx={6} md={6} lg={6}>
                                    <Typography sx={{ fontWeight: "600" }}>{`${ele
                                        .charAt(0)
                                        .toUpperCase()}${ele.slice(1)}`}</Typography>
                                    {formState[ele] ? (
                                        <Typography>{formState[ele]}</Typography>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            ))
                        ) : (
                            <></>
                        )}

                        {resume ? (
                            <Grid item sx={6} md={6} lg={6}>
                                <Typography sx={{ fontWeight: "600" }}>{"Resume"}</Typography>
                                {resume.name ? (
                                    <Box
                                        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                                    >
                                        <Box
                                            sx={{
                                                padding: "5px 10px",
                                                background: "red",
                                                borderRadius: "6px 0px 0px 6px",
                                                color: "white",
                                            }}
                                        >{`.${resume?.name?.split(".").length > 1
                                                ? resume.name.split(".")[1]
                                                : "pdf"
                                            }`}</Box>
                                        <Typography>{resume?.name}</Typography>
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                );
            default:
                return <></>;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, resume, errors,formState]);

    if (isLoading) {
        return <Loader />;
    }
    const handleDialogClose = (e) => {
        e?.preventDefault();
        setResume(false);
        setActiveStep(0);
        handleClose();
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #FF6525",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ fontWeight: "bold" }}>{`Apply to ${jobDetails.title ? jobDetails.title : ""
                        }`}</Typography>
                    <Button onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "auto",
                        height: "max-content",
                        background: "white",
                        rowGap: "20px",
                        padding: "30px 24px",
                    }}
                >
                    <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ padding: "20px 20px" }}>
                        <React.Fragment>{getStepContent()}</React.Fragment>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            pt: 2,
                            justifyContent: "flex-start",
                        }}
                    >
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Button onClick={activeStep !== 2 ? handleNext : handleSubmit}>
                            {activeStep === 2 ? "Submit Application" : "Next"}
                        </Button>
                        {/* {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                            <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                Step {activeStep + 1} already completed
                            </Typography>
                        ) : (
                            <Button onClick={handleComplete}>
                                {activeStep === 2
                                    ? 'SubmitApplication'
                                    : 'Complete Step'}
                            </Button>
                        ))} */}
                    </Box>
                </Box>
            </Dialog>

            <Snackbar
                open={isSnackBarOpen}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
            >
                <Alert
                    onClose={handleSnackBarClose}
                    elevation={6}
                    severity={isError ? "error" : "success"}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ModalFormStepper;
