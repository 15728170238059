import axios from 'axios';
import { auth } from '../firebase';

// const ROOT_URL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5001/newhatch-1/us-central1' : 'https://us-central1-newhatch-1.cloudfunctions.net'
const ROOT_URL = 'https://us-central1-newhatch-1.cloudfunctions.net'
console.log('ROOT_URL', ROOT_URL, process.env.NODE_ENV)
export const addJobPost = async (jobPostData) => {
  const user = auth.currentUser;
  const idToken = await user?.getIdToken();
  const payload = {
    title: jobPostData.title,
    experience: jobPostData.experience,
    location: jobPostData.location,
    description: jobPostData.description,
    jobDescription: jobPostData.jobDescription || [],
  };
  const isValid = jobPostData.title && jobPostData.experience && jobPostData.jobDescription
  if (!isValid) {
    return;
  }
  try {
    await axios.post(process.env?.ADD_POST_JOB_CLOUD_URL || `${ROOT_URL}/addJobPost`, payload, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    // console.log(response.data);
    return;
  } catch (error) {
    console.error('Error adding job post: ', error);
  }

};

export const updateJobPost = async ({ id, jobDescription, title, location, description, experience, createdAt, updatedAt }) => {
  const user = auth.currentUser;
  const idToken = await user?.getIdToken();
  try {
    await axios.post(process.env?.UPDATE_POST_JOB_CLOUD_URL || `${ROOT_URL}/updateJobPost`, {
      jobId: id,
      jobDescription,
      title,
      location,
      description,
      experience,
    },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
    return;
  } catch (error) {
    console.error('Error updating job post: ', error);
  }
};


export const getJobPost = async (jobId) => {

  const user = auth.currentUser;
  const idToken = await user?.getIdToken();

  try {

    const response = jobId ? await axios.post(process.env?.GET_POST_JOB_CLOUD_URL || `${ROOT_URL}/getJobPosts`, {
      jobId
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }) : await axios.post(process.env?.GET_POST_JOB_CLOUD_URL || `${ROOT_URL}/getJobPosts`, {
      jobId
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    console.log('result', response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching job: ', error);
    return [];
  }

}
export const deleteJobPost = async (jobId) => {
  const user = auth.currentUser;
  const idToken = await user?.getIdToken();
  try {

    await axios.post(process.env?.DELETE_POST_JOB_CLOUD_URL || `${ROOT_URL}/deleteJobPost`, {
      jobId,
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return;
  } catch (error) {
    console.error('Error removing job: ', error);
  }
}


export const getReachOuts = async () => {

  const user = auth.currentUser;
  const idToken = await user?.getIdToken();

  try {

    const response =
      await axios.post(process.env?.GET_REACH_OUTS_CLOUD_URL || `${ROOT_URL}/getReachOuts`, undefined, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

    return response.data;
  } catch (error) {
    console.error('Error fetching reachouts: ', error);
    return [];
  }

}



export const deleteReachOut = async (id) => {
  const user = auth.currentUser;

  const idToken = await user?.getIdToken();
  try {

    await axios.post(process.env?.DELETE_REACH_OUT_CLOUD_URL || `${ROOT_URL}/deleteReachOut`, {
      id,
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return;
  } catch (error) {
    console.error('Error removing job: ', error);
  }
}

export const postFeedback = async ({ fullName = '', designation = '', rating = '', message = '', imageUrl = '' }) => {
  const user = auth.currentUser;
  const idToken = await user?.getIdToken();
  if (!(fullName && rating && message)) return;
  try {

    const response = await axios.post(process.env?.POST_FEEDBACK_CLOUD_URL || `${ROOT_URL}/addUserFeedback`, {
      fullName, designation, rating, message, imageUrl,
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return response.status === 200;
  } catch (error) {
    console.error('Error removing job: ', error);
  }
}

export const getUsersFeedback = async () => {
  const user = auth.currentUser;
  const idToken = await user?.getIdToken();
  try {

    const response = await axios.post(process.env?.GET_FEEDBACK_CLOUD_URL || `${ROOT_URL}/getUserFeedback`, undefined, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });



    return response.data;
  } catch (error) {
    console.error('Error fetching user feedback: ', error);
  }
}

export const deletUserFeedback = async (id) => {
  const user = auth.currentUser;

  const idToken = await user?.getIdToken();
  try {

    await axios.post(process.env?.DELETE_USER_FEEDBACK_CLOUD_URL || `${ROOT_URL}/deleteUserFeedback`, {
      id,
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return;
  } catch (error) {
    console.error('Error deleting feedback: ', error);
  }
}