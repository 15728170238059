import { Box, Container, useMediaQuery } from "@mui/material";
import React from "react";

const IntroBanner = ({ children, url, height, align }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");

  const styles = {
    introBanner: {
      height: isMobile ? "300px" : height || "339px",
      // width: '100%',
      display: "block",
      backgroundImage: url || `url(${require("../assets/intro-banner.png")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
      margin: isMobile
        ? "80px 0px 0px 0px"
        : isTablet
        ? "95px 0px 0px 0px"
        : "100px 80px 0px 80px",
      borderRadius: "15px",
    },
  };
  return (
    <Box sx={styles.introBanner}>
      <Container sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: align || "center",
            height: "100%",
            width: "100%",
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default IntroBanner;
