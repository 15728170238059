import { Container, Typography, Snackbar, Alert, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete';

import { deleteReachOut, getReachOuts, } from '../../utils/admin';

import { copyToClipboard } from '../../utils/common';
import Loader from '../../components/Loader';
const ViewReachOuts = () => {


    const [isLoading, setIsLoading] = useState(false);
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [message, setMessage] = useState("");

    // const [jobDescriptions, setjobDescriptions] = useState([]);
    const [reachOuts, setReachOuts] = useState([]);

    // const style = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     bgcolor: 'background.paper',
    //     boxShadow: 40,
    //     p: 4,
    //     '.toolbar-class': {
    //         padding: '10px',
    //         position: 'sticky',
    //         top: '0',
    //         'zIndex': '100'
    //     }
    // };

    const columns = [
        // { field: 'id', headerName: 'ID', width: 200, editable: false, },
        {
            field: 'name',
            headerName: 'Title',
            width: 150,
            editable: true,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'message',
            headerName: 'Message',
            width: 300,
            type: 'text',
            editable: true,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 150,
            editable: true,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'email',
            headerName: 'Email',
            type: 'link',
            width: 150,
            getActions: (cellValues) => {
                const onClickCopy = async () => {
                    copyToClipboard(`${cellValues?.row?.email || ''}`)
                    //    setIsError(true);
                    setIsSnackBarOpen(true);
                    setMessage("Link copied to clipboard");
                }
                return <Box onClick={onClickCopy}>{cellValues?.email || ''}</Box>;
            },
            editable: false,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 150,
            editable: true,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            getActions: (cellValues) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={async (e) => {
                            e.preventDefault()
                            setIsLoading(true)
                            if (cellValues?.row?.id) {
                                try {
                                    deleteReachOut(cellValues?.row?.id);
                                    // console.log(data)

                                    setIsLoading(false)

                                    // window.location.reload()
                                } catch (error) {
                                    // console.log(error)
                                    setIsLoading(false)
                                }
                            }

                        }}
                    />
                ];
            },
            headerClassName: 'center-aligned-header',
        },
    ];

    const getUpdatedReachOuts = async () => {
        setIsLoading(true)
        const JobData = await getReachOuts()
        if (JobData?.length) {
            setReachOuts(JobData)
        }
        setIsLoading(false)
    }



    useEffect(() => {
        getUpdatedReachOuts()
    }, [])

    if (isLoading) return <Loader />
    return (
        <Container sx={{ minHeight: '85vh' }}>
            <Typography sx={{ marginLeft: '500px', marginY: '30px' }} variant="h6" gutterBottom>
                Messages
            </Typography>
            <Box sx={{ height: '100%', width: '100%' }}>
                <DataGrid
                    rows={reachOuts}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    // checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
            <Snackbar open={isSnackBarOpen} autoHideDuration={6000} onClose={() => setIsSnackBarOpen(false)}>
                <Alert onClose={() => setIsSnackBarOpen(false)} elevation={6} severity={'success'} sx={{ width: '100%', }}>
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default ViewReachOuts