import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import IntroBanner from "../components/IntroBanner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useParams } from "react-router-dom";
import { ABOUT_US_PAGE_CONTENT } from "../content/pageStaticContent";




const About = () => {
  const { slug } = useParams();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");

  const { ourApproach, ourProcess, whyPartnerUs } = ABOUT_US_PAGE_CONTENT;

  const styles = {
    container: {
      padding: "0px 40px 0px 40px",
      height: "100%",
      width: "100%",
    },
    fullScreenBanner3: {
      minHeight: "200px",
      height: "100%",
      width: "100%",
      background: `url(${require("../assets/intro-banner.png")})`,
      backgroundSize: "cover",
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center',
      objectFit: "cover",
      borderRadius: "15px",
      marginTop: "50px",
    },
    imageContainer: {
      height: "540px",
      width: isMobile ? "auto" : isTablet ? "340px" : "480px",
      display: "block",
      backgroundImage: `url(${require("../assets/about-heading.png")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
    },
    ourMissionContainer: {
      height: "100%",
      width: "100%",
      display: "block",
      backgroundImage: `url(${require("../assets/about-ourmission.png")})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      objectFit: "cover",
      mixBlendMode: "luminosity",
      paddingBottom: "40px",
      borderRadius: "15px",
    },
  };
  useEffect(() => {
    if (slug) {
      setTimeout(() => {
        const id = slug;
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  });

  return (
    <>
      <IntroBanner>
        <Typography
          sx={{
            color: "#004DD8",
            letterSpacing: "0px",
            paddingBottom: "40px",
          }}
          variant="h3"
        >
          {"About us"}
        </Typography>
        <Divider
          variant="fullWidth"
          sx={{ border: "2px solid #FF6525", width: "96px" }}
        />
        <Typography sx={{ paddingTop: "22px" }}>
          {"We make technology work for you"}
        </Typography>
      </IntroBanner>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
            gap: isMobile ? "40px" : isTablet ? "30px" : "119px",
            height: "100%",
            width: "100%",
            paddingTop: "80px",
            paddingBottom: "80px",
          }}
        >
          <Box>
            <Box sx={styles.imageContainer} />
          </Box>
          <Box sx={{ textAlign: "left" }}>
            <Typography
              sx={{ color: "#848484", fontSize: "14px", paddingBottom: "5px" }}
            >
              {"ABOUT US"}
            </Typography>
            <Typography
              sx={{ color: "#004DD8", fontSize: "34px", paddingBottom: "20px" }}
            >
              {"Who we are"}
            </Typography>
            <Divider
              variant="fullWidth"
              sx={{ border: "2px solid #FF6525", width: "96px" }}
            />
            <Typography
              sx={{
                color: "#606060",
                fontSize: "16px",
                width: isMobile ? "auto" : isTablet ? "344px" : "441px",
                paddingTop: "24px",
              }}
            >
              {
                "NewHatch is a cutting-edge IT consulting company that offers organisations to help them make informed technology decisions and improve their IT systems and processes. Newhatch is revolutionising the way businesses find and hire top talent. With a focus on technology and innovation, NewHatch uses advanced algorithms and data analysis to match job seekers with the perfect job opportunities. One of the key features of NewHatch is its ability to identify and attract top talent, even in highly competitive industries. By leveraging the latest technology and data analysis techniques, NewHatch can quickly and accurately identify the best candidates for any position, even in fields where there is a shortage of qualified applicants."
              }
            </Typography>
          </Box>
        </Box>
        {/* our missions */}
        {/* <Box sx={styles.ourMissionContainer}>
          <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', padding: '60px 0px' }}>
            <Box sx={{ width: 'max-content', height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box >
                <Typography sx={{
                  color: '#848484',
                  letterSpacing: '0px',
                  fontSize: '14px',
                  paddingBottom: '0px',
                }}>
                  {'Our'}
                </Typography>
                <Typography sx={{ color: '#004DD8', fontSize: '34px' }}>{'Mission'}</Typography>
              </Box>
              <Box sx={{ paddingTop: '20px' }}><Typography sx={{ color: '#606060', fontSize: '16px', width: 'auto', maxWidth: isMobile ? '340px' : isTablet ? '700px' : '1027px' }}>{`The mission of Newhatch is to help organizations leverage technology to achieve their goals and objectives. The specific mission of Newhatch can vary depending on the company's focus and the markets it serves, but some common elements of a mission statement for an IT consulting company might include`}</Typography>
              </Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', width: '100%', paddingTop: '20px' }}>
                {
                  ourMissions.map((ele, idx) => (<Box sx={{ display: 'flex', gap: '18px', justifyContent: 'flex-start', height: '100%', alignItems: 'center', paddingBottom: isMobile ? '15px' : '0px' }}>
                    <Typography sx={{ color: '#004DD8', fontSize: '73px' }}>{idx + 1}</Typography>
                    <Typography sx={{ width: isMobile ? '200px' : isTablet ? '255px' : '344px', color: '#606060', fontSize: '16px' }}>{ele}</Typography>
                  </Box>))
                }
              </Box>
            </Box>
          </Box>
        </Box> */}
        {/* our process */}

        <Box
          id={"process"}
          sx={{
            display: "flex",
            width: "100%",
            height: isMobile ? "100%" : "600px",
            justifyContent: "center",
            padding: "60px 0px",
          }}
        >
          <Box
            sx={{
              width: "max-content",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#848484",
                  letterSpacing: "0px",
                  fontSize: "14px",
                  paddingBottom: "0px",
                }}
              >
                {"Our"}
              </Typography>
              <Typography sx={{ color: "#004DD8", fontSize: "34px" }}>
                {"Process"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                width: "100%",
                paddingTop: "20px",
                rowGap: "30px",
                columnGap: "60px",
              }}
            >
              {ourProcess.map((ele, idx) => (
                <Box
                  key={`${idx}-${ele.title}`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "18px",
                    justifyContent: "space-between",
                    height: "100%",
                    alignItems: "center",
                    rowGap: "15px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      {ele.title}
                    </Typography>
                    <Typography
                      sx={{
                        width: isMobile
                          ? "200px"
                          : isTablet
                          ? "327px"
                          : "517px",
                        color: "#606060",
                        fontSize: "14px",
                      }}
                    >
                      {ele.description}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Divider
                      variant="fullWidth"
                      sx={{ border: "2px solid #FF6525", width: "96px" }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          background:
            "transparent linear-gradient(106deg, #004DD8 0%, #3EB9E9 100%) 0% 0% no-repeat padding-box",
          padding: "40px 0px 55px 0px",
          color: "white",
        }}
      >
        <Container
          sx={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ border: "1px solid white", height: "134px" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "30px",
              }}
            >
              <Typography sx={{ fontSize: "34px" }}>
                {"Why partner with us?"}
              </Typography>
              <Typography sx={{ fontSize: "18px", width: "600px" }}>
                {
                  "Our channel-friendly, collaborative program focuses on building a relationship that benefits you and your customers. Our program offers:"
                }
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
              paddingTop: "44px",
              rowGap: "20px",
            }}
          >
            {whyPartnerUs.map((ele, idx) => (
              <Box key={`${idx}-${ele}`} sx={{ display: "flex" }}>
                <CheckCircleIcon sx={{ color: "white" }} />{" "}
                <Typography sx={{ width: "357px", fontSize: "14px" }}>
                  {ele}
                </Typography>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
      <Container sx={{ marginTop: "50px" }}>
        <Box
          id={"approach"}
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            padding: "60px 0px",
          }}
        >
          <Box
            sx={{
              width: "max-content",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#848484",
                  letterSpacing: "0px",
                  fontSize: "14px",
                  paddingBottom: "0px",
                }}
              >
                {"Our"}
              </Typography>
              <Typography sx={{ color: "#004DD8", fontSize: "34px" }}>
                {"Approach"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                width: "100%",
                paddingTop: "20px",
                rowGap: "30px",
                columnGap: "60px",
              }}
            >
              {ourApproach.map((ele, idx) => (
                <Box
                  key={`${idx}-${ele.title}`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "18px",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "left",
                    rowGap: "20px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "18px",
                        textAlign: "left",
                        paddingBottom: "15px",
                        fontWeight: "500",
                      }}
                    >
                      {ele.title}
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Divider
                        variant="fullWidth"
                        sx={{ border: "2px solid #FF6525", width: "96px" }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      width: isMobile ? "200px" : isTablet ? "327px" : "517px",
                      color: "#606060",
                      fontSize: "14px",
                    }}
                  >
                    {ele.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box sx={styles.fullScreenBanner3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "230px",
              opacity: "0.77",
              background:
                "linear-gradient(106deg, #0659DA 20%, #3EB9E9 80%) 0% 0% no-repeat padding-box",
              padding: "40px 0px",
              borderRadius: "20px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "900",
                fontSize: "1.8em",
                color: "white",
              }}
            >
              {"Ready to kickstart your tech career ?"}
            </Typography>
            <Box sx={{ textAlign: "center", padding: "20px 0px" }}>
              <Button
                sx={{
                  color: "#004DD8",
                  background: "white",
                  ":hover": {
                    background: "white",
                    outline: "2px solid #3EB9E9",
                  },
                }}
                size="medium"
              >
                <Link
                  href="/contact"
                  sx={{
                    textAlign: "center",
                    fontWeight: "900",
                    fontSize: "1.8em",
                    textDecoration: "none",
                  }}
                >
                  {"Join us now!"}
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default About;
