
const getNavsProps = () => {
    return new Promise((resolve) => {
        const navs = [
            {
                title: 'About',
                url: '/about',
                subNav: [
                    { title: 'Our Process', url: '/about/process' },
                    { title: 'Our Approach', url: '/about/approach' },
                ]
            },
            {
                title: 'Services',
                url: '/service',
                subNav: []
            },
            {
                title:'Career',
                url:'/career',
                subNav: []
            },
            {
                title: 'Leadership',
                url: '/leadership',
                subNav: [
                    { title: 'Our Mission', url: '/leadership/mission' }
                ]
            },
            {
                title: 'Lets talk',
                url: '/contact',
                subNav: []
            }
        ]
        resolve(navs);
    })
}
const getServicesList=()=>{
    return   [
        {
          title: "TECHNOLOGY CONSULTING",
          desciption:
            "The Newhatch team offers to help organisations plan, design, and implement technology solutions. Some of the most common technology consulting services available are:",
          list: [
            "Expertise and experience: IT consultants bring a wealth of experience and knowledge to organisations, helping them identify and solve complex technology-related problems.",
            `Fresh perspective: An outside consultant can provide a fresh perspective on a company's technology-related challenges, helping to identify new solutions and approaches.`,
            `Access to cutting-edge technology: IT consultants often have access to the latest technology and tools, which they can leverage to help organisations keep pace with the rapidly changing tech landscape.`,
            `Cost savings: IT consultants can help organisations avoid costly technology mistakes and reduce long-term IT expenses.`,
            `Improved efficiency: By streamlining and optimising technology systems, IT consultants can help organisations improve efficiency and productivity, freeing up resources for other important tasks.`,
            `Risk management: IT consultants can help organisations identify and mitigate potential technology risks, protecting their critical data and systems from harm.`,
          ],
          sx: {
            background: "#FFFCF9 0% 0% no-repeat padding-box",
            border: "1px solid #FFDDBB",
          },
          id: "TECHNOLOGYCONSULTING",
        },
        {
          title: "SOFTWARE DEVELOPMENT",
          desciption:
            "Our developers offer to design, develop, and maintain software applications. Some of the most common software development services available are:",
          list: [
            `Web development : designing and developing websites and web applications.`,
            `Mobile app development : designing and developing applications for mobile devices, such as smartphones and tablets.`,
            `Custom software development : designing and developing software applications tailored to specific business needs and requirements.`,
            `Cloud application development : designing and developing cloud-based applications that can be accessed over the internet.`,
            `E-commerce development : designing and developing online stores and platforms for buying and selling products and services.`,
            `Game development : designing and developing video games for various platforms, including desktop, mobile, and console.`,
            `Enterprise software development :designing and developing software solutions for large organizations, including enterprise resource planning (ERP) and customer relationship management (CRM) systems.`,
            `Software maintenance and support : providing ongoing support and maintenance for existing software applications.`,
          ],
          sx: {
            background: "#F2FCFF 0% 0% no-repeat padding-box",
            border: "1px solid #87CBFF",
          },
          id: "SOFTWAREDEVELOPMENT",
        },
        {
          title: "TESTING / QA",
          desciption: `Our team focus on automating various testing and quality assurance processes to ensure the quality and reliability of software applications. Some of the most common testing/QA automation services available are:`,
          list: [
            `Unit testing : automated testing of individual units of code, such as functions or classes, to ensure they work as expected.`,
            `Integration testing : automated testing of multiple units of code working together to ensure they integrate and work correctly.`,
            `Functional testing : automated testing of the functionality of a software application to ensure it meets specified requirements.`,
            `Regression testing : automated testing of the previously tested software to ensure new changes have not introduced any unintended consequences.`,
            `Performance testing : automated testing to measure and evaluate the performance of a software application under various conditions, such as load, stress, and scalability.`,
            `Security testing : automated testing to identify and evaluate the security vulnerabilities of a software application.`,
            `User acceptance testing (UAT) : automated testing performed by end users to verify that a software application meets their needs and requirements.`,
            `Continuous integration and continuous delivery (CI/CD) : automated processes for building, testing, and deploying software changes continuously.`,
          ],
          sx: {
            background: "#F6F8FF 0% 0% no-repeat padding-box",
            border: "1px solid #88A3FF",
          },
          id: "TESTING",
        },
        {
          title: "CLOUD SERVICES",
          desciption:
            "Newhatch team offers to help organizations plan, design, and implement technology solutions",
          list: [
            `Infrastructure as a Service (IaaS) : Provides virtualised computing resources, such as virtual machines, storage, and networking.`,
            `Platform as a Service (PaaS) : provides a platform for the development, deployment, and management of applications and services.`,
            `Software as a Service (SaaS) : provides access to software applications over the internet, typically on a subscription basis.`,
            `Storage as a Service (STaaS) : provides online storage capacity for data and files.`,
            `Database as a Service (DBaaS) : provides a managed database service, including provisioning, backup, and recovery.`,
            `Function as a Service (FaaS) : enables the deployment of individual functions or blocks of code that run in response to specific events or triggers.`,
          ],
          sx: {
            background: "#F2FCFF 0% 0% no-repeat padding-box",
            border: "1px solid #87CBFF",
          },
          id: "CLOUDSERVICES",
        },
        {
          title: "CYBERSECURITY",
          desciption:
            "Our Team offers to protect organisations and individuals from cyber threats, such as hacking, malware, and data breaches. Some of the most common cybersecurity services available are:",
          list: [
            `Managed security services : Outsourcing the management of an organisation's security needs to a third-party provider.`,
            `Penetration testing : simulated attacks on a system to identify and evaluate security vulnerabilities.`,
            `Vulnerability scanning : automated scanning of systems to identify and prioritise security vulnerabilities.`,
            `Security information and event management (SIEM) : real-time analysis of security-related data to identify and respond to potential security incidents.`,
            `Firewall management : management of firewall devices to ensure the security of network traffic.`,
            `Identity and access management (IAM) : managing and controlling access to sensitive systems and data.`,
            `Encryption services : the use of encryption to protect sensitive data in transit and at rest.`,
            `Disaster recovery and business continuity planning : planning and preparation for responding to and recovering from a cybersecurity incident.`,
          ],
          sx: {
            background: "#F6F8FF 0% 0% no-repeat padding-box",
            border: "1px solid #88A3FF",
          },
          id: "CYBERSECURITY",
        },
        {
          title: "STAFF AUGEMENTATION",
          desciption: `Newhatch offers outsourcing of additional personnel to support an organisation's workforce. These services are used to supplement an organisation's existing staff with skilled professionals to address short-term or project-based needs. Some of the most common staff augmentation services available are:`,
          list: [
            `IT staff augmentation : Outsourcing of IT professionals to support an organisation's technology needs, such as software development and network administration.`,
            `Financial staff augmentation : outsourcing of financial professionals to support an organisation's accounting and financial reporting needs.`,
            `Administrative staff augmentation : outsourcing of administrative professionals to support an organisation's administrative tasks, such as data entry and customer service.`,
            `Marketing staff augmentation : outsourcing of marketing professionals to support an organisation's marketing and branding efforts.`,
          ],
          sx: {
            background: "#FFFCF9 0% 0% no-repeat padding-box",
            border: "1px solid #FFDDBB",
          },
          id: "STAFFAUGEMENTATION",
        },
        {
          title: "IoT development and consultancy",
          desciption: `Discover limitless possibilities with our IoT development and consultancy services. Tailored solutions, expert guidance, and innovative strategies empower businesses to thrive in the interconnected world. Stay ahead with our cutting-edge technology solutions designed for success.`,
          list: [
              `Customized IoT application development:Get tailor-made IoT applications to suit your unique requirements. Our development services ensure that your IoT solutions align perfectly with your business needs, delivering a seamless and efficient user experience.`,
              `Integration of IoT solutions with existing systems:Seamlessly integrate IoT solutions with your current systems. Our expertise ensures a smooth transition and optimal performance, enhancing the capabilities of your existing infrastructure.`,
              `Consultancy on IoT strategy and implementation:Receive expert consultancy on IoT strategy and implementation. Our guidance helps businesses navigate the complexities of IoT, enabling them to make informed decisions and achieve successful implementation.`,
              `IoT security and data analytics services:Ensure the security of your IoT ecosystem with our dedicated IoT security and data analytics services. Our solutions provide comprehensive protection and valuable insights, enhancing the overall reliability of your IoT infrastructure.`,
            ],
          sx: {
              background: "#FFFCF9 0% 0% no-repeat padding-box",
              border: "1px solid #7DBDAB",
          },
          id: "IOTDEVELOPMENT",
      },
      {
          title: "Industrial Automation(IIoT) ",
          desciption: `Revolutionise your industry with our industrial automation and consultancy services. Our tailored solutions optimize processes, enhance efficiency, and drive innovation. Trust us to elevate your operations, ensuring a seamless transition to the future of industrial excellence.`,
          list: [
              `IoT implementation for smart manufacturing:Revolutionize your manufacturing processes with our IoT implementation. Smart sensors, connectivity, and data analytics converge to create an intelligent ecosystem for efficient and informed decision-making.`,
              `Industrial automation solutions for process optimization:Enhance efficiency and optimize your industrial processes with our automation solutions. From robotic automation to intelligent control systems, we provide comprehensive solutions for industrial excellence.`,
              `Consultancy on Industry 4.0 strategies:Navigate the landscape of Industry 4.0 with our expert consultancy services. We offer strategic insights and guidance to help your business leverage the full potential of the latest industrial technologies.`,
              `Integration of automation systems with existing infrastructure:Seamlessly integrate automation into your existing infrastructure. Our solutions ensure a harmonious blend of new technologies with your current systems, enhancing overall performance and efficiency.`,
            ],
          sx: {
              background: "#F8EFEA 0% 0% no-repeat padding-box",
              border: "1px solid #76AD92",
          },
          id: "INDUSTRIALAUTOMATION",
      },
      {
          title: "Generative AI solutions",
          desciption: `Transform possibilities into reality with our Generative AI solutions. Unleash creativity and efficiency through cutting-edge technology. Tailored to your needs, our solutions redefine innovation, paving the way for a future where AI shapes limitless possibilities for your business.`,
          list: [
              `Generative AI for creative content creation:Explore the realm of creative possibilities with our Generative AI solutions. From imaginative content generation to artistic expression, our AI empowers creativity at its best.`,
              `Efficient automation of complex tasks through AI:Streamline and enhance your operational efficiency with our AI-driven automation solutions. Tackle intricate tasks with ease, optimizing processes for maximum productivity.`,
              `Customized AI solutions for unique business:Tailor-made AI solutions to suit the distinctive needs of your business. Our customized approach ensures that AI aligns seamlessly with your unique requirements, unlocking new possibilities.`,
              `Integration of generative models in your workflow:Revolutionize your workflow by integrating advanced generative models. Our AI solutions seamlessly blend into your processes, adding innovation and efficiency to every step.`,
          ],
          sx: {
              background: "#E4F1F6 0% 0% no-repeat padding-box",
              border: "1px solid #58A4B0",
          },
          id: "GENERATIVEAI",
      },
      ];
}

export {
    getNavsProps,
    getServicesList
}