import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Modal } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import { Container } from '@mui/system';
import { useState } from 'react';
import { addJobPost } from '../../utils/admin';
import draftjsToHtml from 'draftjs-to-html';
import Loader from '../../components/Loader';
import DeleteIcon from '@mui/icons-material/Delete';
import { generateUUID } from '../../utils/common';
import CloseIcon from '@mui/icons-material/Close';

const PostJobs = () => {
     const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          boxShadow: 40,
          p: 4,
          '.toolbar-class': {
               padding: '10px',
               position: 'sticky',
               top: '0',
               'zIndex': '100'
          }
     };
     const [open, setOpen] = useState(false);
     const [jobDescriptions, setjobDescriptions] = useState([]);
     const [isLoading, setIsLoading] = useState(false);
     const [editorState, setEditorState] = useState(EditorState.createEmpty());
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);
     const jobFormData = React.useRef({});
     const jdFormData = React.useRef({});

     const handleOnchange = (name, value) => {
          const payload = jobFormData.current;

          payload[`${name}`] = value;

          jobFormData.current = payload;
     }

     const jdFormHandleOnchange = (name, value) => {
          const payload = jdFormData.current;

          if (name && value) {
               payload[name] = value;
          }

          jdFormData.current = payload;
     }

     const handleEditorStateChange = newEditorState => {
          setEditorState(newEditorState);
     };

     const jdFormOnSubmit = () => {
          if (editorState) {
               const rawContentState = convertToRaw(editorState.getCurrentContent());
               const htmlString = draftjsToHtml(rawContentState);
               const jdPayload = jdFormData.current;
               const jds = jobDescriptions;
               jdPayload['content'] = htmlString;

               if (jdPayload.content) jds.push({ ...jdPayload, id: generateUUID() });
               setjobDescriptions(jds);
               // console.log(jds);

               handleEditorStateChange(EditorState.createEmpty());
               jdFormData.current = {};
               setOpen(false);
          }
     }

     const removeJobDescription = (id) => {
          if (id) {
               const jds = jobDescriptions.filter(ele => ele?.id && ele?.id !== id);
               setjobDescriptions([...jds])
          }
     }

     const handleSubmit = async () => {

          try {
               const payload = { ...jobFormData.current, jobDescription: jobDescriptions };
               setIsLoading(true);
               if (payload?.jobDescription && payload.title && payload.experience && payload.location) {
                    await addJobPost(payload);
                    // console.log(response);
               }
               else {
                    console.log('please provide required fields');
               }
               setIsLoading(false);
               jdFormData.current = null;
               handleEditorStateChange(EditorState.createEmpty());
               window.location.reload()
          } catch (error) {
               console.error(error);
               setIsLoading(false);
               jdFormData.current = null;
               handleEditorStateChange(EditorState.createEmpty());
          }


     }
     if (isLoading) return <Loader />
     return (
          <Container sx={{ height: '85vh' }}>
               <Typography sx={{ marginLeft: '500px', marginTop: '30px' }} variant="h6" gutterBottom>
                    Post Job
               </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={4} sm={4}>
                         <TextField
                              required
                              id="title"
                              name="title"
                              label="Title"
                              onChange={(e) => {
                                   handleOnchange(e.currentTarget.name, e.currentTarget.value)
                              }}
                              fullWidth
                              autoComplete="title"
                              variant="standard"
                         />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                         <TextField
                              required
                              id="experience"
                              name="experience"
                              label="Experience"
                              onChange={(e) => {
                                   handleOnchange(e.currentTarget.name, e.currentTarget.value)
                              }}
                              fullWidth
                              autoComplete="experience"
                              variant="standard"
                         />
                    </Grid>
                    {/* add location dropdown with set of required fields */}
                    <Grid item xs={4} sm={4}>
                         <TextField
                              required
                              id="location"
                              name="location"
                              label="Location"
                              onChange={(e) => {
                                   handleOnchange(e.currentTarget.name, e.currentTarget.value)
                              }}
                              fullWidth
                              autoComplete="locrequiredation"
                              variant="standard"
                         />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                         <TextField
                              multiline
                              rows={4}
                              id="description"
                              onChange={(e) => {
                                   handleOnchange(e.currentTarget.name, e.currentTarget.value)
                              }}
                              name="description"
                              label="Description"
                              fullWidth
                              autoComplete="description"
                              variant="standard"
                         />
                    </Grid>
                    <Grid item xs={11} sm={11}>
                         <b sx={{ marginTop: '30px' }}>
                              Job Description
                         </b>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                         <AddCircleOutlineIcon onClick={handleOpen} />
                    </Grid>

                    <Grid item xs={12}>
                         {jobDescriptions?.length ? jobDescriptions.map((item, idx) => (
                              <Accordion sx={{ height: 'max-content', padding: '20px 12px' }} key={`${idx}-${item?.id}`}>
                                   <AccordionSummary
                                        expandIcon={<ExpandCircleDownIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                   >
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}><Typography>{item.title}</Typography><Button onClick={() => removeJobDescription(item?.id)}><DeleteIcon /></Button></Box>
                                   </AccordionSummary>
                                   <AccordionDetails>
                                        <Box component={'div'} dangerouslySetInnerHTML={{ __html: item?.content }}></Box>
                                   </AccordionDetails>
                              </Accordion>
                         )) : <></>
                         }
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "center", marginBottom: "5px" }}>
                         <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                    </Grid>


                    <Modal
                         open={open}
                         onClose={handleClose}
                         aria-labelledby="modal-modal-title"
                         aria-describedby="modal-modal-description"
                    >

                         <Box sx={style}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                   <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Add Job Description
                                   </Typography>
                                   <Button onClick={() => setOpen(false)}>
                                        <CloseIcon />
                                   </Button>
                              </Box>
                              <Grid item md={12}>
                                   <TextField
                                        required
                                        id="title"
                                        name="title"
                                        label="Title"
                                        fullWidth
                                        onChange={(e) => jdFormHandleOnchange(e.currentTarget.name, e.currentTarget.value)}
                                        autoComplete="title"
                                        variant="standard"
                                   />
                              </Grid>

                              <Typography variant="h6" sx={{ margin: '20px 0px', }}>
                                   Content
                              </Typography>
                              <Grid item xl={12} style={{
                                   height: 'auto', maxHeight: '300px',
                                   overflowY: 'auto',
                              }}>
                                   <Editor
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        editorState={editorState}
                                        onEditorStateChange={handleEditorStateChange}

                                        toolbar={{
                                             options: [
                                                  "inline",
                                                  "blockType",
                                                  "list",
                                                  "link",
                                                  "emoji",
                                                  "image",
                                                  "remove",
                                                  "history",
                                             ],
                                             inline: {
                                                  options: [
                                                       "bold",
                                                       "italic",
                                                       "underline",
                                                       "strikethrough",
                                                  ],
                                             },
                                        }}
                                   />
                              </Grid>
                              <Grid item xs={12}>
                                   <Button variant="contained" onClick={jdFormOnSubmit}>Add</Button>
                              </Grid>
                         </Box>
                    </Modal>

               </Grid>
          </Container>
     );
};

export default PostJobs