import { Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete';


import { deletUserFeedback, getUsersFeedback, } from '../../utils/admin';

import Loader from '../../components/Loader';

const ViewFeedBacks = () => {


    const [isLoading, setIsLoading] = useState(false);


    const [jobPost, setJobPost] = useState([])
    // const style = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     bgcolor: 'background.paper',
    //     boxShadow: 40,
    //     p: 4,
    //     '.toolbar-class': {
    //         padding: '10px',
    //         position: 'sticky',
    //         top: '0',
    //         'zIndex': '100'
    //     }
    // };

    const columns = [
        { field: 'fullName', headerName: 'Full Name', width: 200, editable: false, },
        {
            field: 'designation',
            headerName: 'Designation',
            width: 150,
            editable: false,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'rating',
            headerName: 'Rating',
            width: 150,
            type: 'number',
            editable: false,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'message',
            headerName: 'Message',
            width: 150,
            editable: false,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'imageUrl',
            headerName: 'Image link',
            width: 150,
            editable: true,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            getActions: (cellValues) => {
                return [

                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={async (e) => {
                            e.preventDefault()
                            setIsLoading(true)
                            if (cellValues?.row?.id) {
                                try {
                                    await deletUserFeedback(cellValues?.row?.id);
                                    // console.log(data)
                                    window.location.reload()
                                    setIsLoading(false)
                                } catch (error) {
                                    // console.log(error)
                                    setIsLoading(false)
                                }
                            }

                        }}
                    />
                ];
            },
            headerClassName: 'center-aligned-header',
        },
    ];

    const getUpdatedFeedbacks = async () => {
        setIsLoading(true)
        const feedback = await getUsersFeedback()
        if (feedback?.length) {
            setJobPost(feedback)
        }
        setIsLoading(false)
    }



    useEffect(() => {
        getUpdatedFeedbacks()
    }, [])

    if (isLoading) return <Loader />

    return (
        <Container sx={{ minHeight: '85vh' }}>
            <Typography sx={{ marginLeft: '500px', marginY: '30px' }} variant="h6" gutterBottom>
                View Feedbacks
            </Typography>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={jobPost}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    // checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
        </Container>
    )
}

export default ViewFeedBacks