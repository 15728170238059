import { Alert, Button, Divider, FormControl, Grid, Snackbar, TextField } from '@mui/material'
import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase';
export const ContactForm = () => {

    const [firstName, SetFirstName] = useState('');
    const [lastName, SetLastName] = useState('');
    const [email, SetEmail] = useState('');
    const [phone, SetPhone] = useState('');
    const [message, SetMessage] = useState('');
    const [isError, SetIsError] = useState(false);
    const [responseMessage, SetResponseMessage] = useState('Message sent.');
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const clickSubmit = async () => {
        try {
            if (firstName && lastName && email && phone) {
                await addDoc(collection(db, "contact"), {
                    name: `${firstName} ${lastName}`,
                    email,
                    phone,
                    message,
                    createdAt: new Date(),
                    updatedAt: new Date()
                });
                // console.log(docRef)
                SetFirstName('');
                SetLastName('');
                SetEmail('');
                SetPhone('');
                SetMessage('');
            }
            SetIsError(false);
            SetResponseMessage('Message sent.')
            handleClick()

        } catch (e) {
            console.error("Error adding document: ", e);
            SetIsError(true);
            SetResponseMessage('Message not sent.')
        }
    }
    return (
        <FormControl sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }} >
            <Grid container spacing={2} sx={{ width: '100%' }}>
                <Grid item md={6} xs={12} sx={{ width: 'auto' }}>
                    <TextField autoComplete='false' aria-label='First Name' label='First Name' type={'text'} value={firstName} onChange={(e) => { SetFirstName(e.target.value || '') }} required={true} sx={{ width: '100%' }} />
                </Grid>
                <Grid item md={6} xs={12} sx={{ width: 'auto' }}>
                    <TextField autoComplete='false' aria-label='Last Name' label='Last Name' type={'text'} value={lastName} onChange={(e) => { SetLastName(e.target.value || '') }} required sx={{ width: '100%' }} />
                </Grid>
                <Grid item md={6} xs={12} sx={{ width: 'auto' }}>
                    <TextField autoComplete='false' aria-label='Email' label='Email' type={'email'} value={email} onChange={(e) => { SetEmail(e.target.value || '') }} required sx={{ width: '100%' }} />
                </Grid>
                <Grid item md={6} xs={12} sx={{ width: 'auto' }}>
                    <TextField autoComplete='false' aria-label='Phone' label='Phone' type={'tel'} value={phone} onChange={(e) => { SetPhone(e.target.value || '') }} required sx={{ width: '100%' }} />
                </Grid>
                <Grid item md={12} xs={12} sx={{ width: 'auto' }}>
                    <TextField autoComplete='false' multiline aria-label='Message' label='Message' placeholder='Write to us.....' type={'text'} value={message} onChange={(e) => { SetMessage(e.target.value || '') }} sx={{ width: '100%' }} />
                </Grid>
                <Divider />
                <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button sx={{ background: 'transparent linear-gradient(105deg, #004DD8 0%, #41BEEA 100%) 0% 0% no-repeat padding-box', color: '#FFFFFF', boxShadow: '0px 7px 23px #0000001C', borderRadius: '5px', padding: '10px 50px' }} size='medium' variant='outlined' onClick={clickSubmit}>{'Send'}</Button>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} elevation={6} severity={isError ? 'error' : 'success'} sx={{ width: '100%', }}>
                    {responseMessage}
                </Alert>
            </Snackbar>
        </FormControl>
    )
}