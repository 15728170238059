// src/redux/store.js
import { createStore, combineReducers } from 'redux';
import authReducer from './auth/authActions';


const rootReducer = combineReducers({
  auth: authReducer,
  // ... other reducers ...
});

const store = createStore(rootReducer);

export default store;
