
import React from 'react'
import Dashboard from './components/Dashboard'
import PageNotFound from '../pages/PageNotFound'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import PostJobs from './pages/PostJobs'
import ViewJobs from './pages/ViewJobs'
import AdminHeader from './components/AdminHeader'
import { Container } from '@mui/material'
import ViewReachOuts from './pages/ViewReachOuts'
import ViewFeedBacks from './pages/ViewFeedbacks'


const Admin = ({ user }) => {


    return (<div>

        <AdminHeader />

        {/* Main content area */}
        <Container>
            {/* Use Switch to render only the first matching Route */}
            <BrowserRouter>
                <Routes>
                    {/* <Route element={<PrivateRoute />}> */}
                    <Route exact path="/" element={<Dashboard />} />
                    {/* <Route exact path="/admin" element={<Admin user={user} />} /> */}
                    <Route exact path="/admin/postJob" element={<PostJobs />} />
                    <Route exact path="/admin/viewJob" element={<ViewJobs />} />
                    <Route exact path="/admin/viewReachOuts" element={<ViewReachOuts />} />
                    <Route exact path="/admin/userFeedbacks" element={<ViewFeedBacks />} />
                    <Route path="*" element={<PageNotFound />} />
                    {/* </Route> */}
                </Routes>

                {/* Redirect to dashboard if no matching route */}
                {/* <Redirect to="/admin/dashboard" /> */}
            </BrowserRouter>
        </Container>
    </div>)
    // return (
    //     user ? <Dashboard /> : <PageNotFound/>
    // )
}


export default Admin 