import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
// import { MainBanner } from '../components/MainBanner'
import IntroBanner from "../components/IntroBanner";
import { HOME_PAGE_CONTENT } from "../content/pageStaticContent";


export const Home = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:840px)");
  const { testimonialList, companyOutput, ourServices } = HOME_PAGE_CONTENT;

  const styles = {
    fullScreenBanner1: {
      display: "block",
      color: "#2D59A6",
      position: "relative",
      width: "100%",
      height: "100%",
      background: "#FFFCF9",
      padding: "60px 0px",
    },
    fullScreenBanner01: {
      display: "block",
      // backgroundImage: 'url("../../public/assets/download.jpg") center center/cover no-repeat',
      color: "#2D59A6",
      position: "relative",
      width: "100%",
      background: "white",
      height: "100%",
    },
    testimonialWrapper: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      height: "100%",
      padding: "70px 0px 70px 0px",
      color: "black",
      margin: " 0 auto",
      position: "relative",
      width: "100%",
      background: "#F2FCFF",
    },
    fullScreenBanner3: {
      minHeight: "200px",
      height: "100%",
      width: "100%",
      // background: `url(${require('../assets/intro-banner.png')})`,
      backgroundSize: "cover",
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center',
      objectFit: "cover",
      mixBlendMode: "color-burn",
    },
    testimonialCard: {
      display: "flex",
      width: "286px",
      height: "354px",
      flexDirection: "column",
      borderRadius: "8px",
      justifyContent: "center",
      margin: "15px",
      ":hover": {
        transformStyle: "preserve-3d",
        transform: "translate3d(0px, 0px, 0px) scale(1.2)",
        background: "linear-gradient(45deg, #388bc8,#f5f5f5)",
        cursor: "pointer",
        border: "1px solid",
        color: "#23242C",
      },
    },
  };

  return (
    <>
      <IntroBanner height={"518px"} align={"left"}>
        <Box>
          <Typography
            sx={{
              color: "#004DD8",
              letterSpacing: "0px",
              paddingBottom: isMobile ? "20px" : "40px",
              width: "381px",
              fontWeight: "600",
            }}
            variant={isMobile ? "h4" : "h3"}
          >
            {"Expert guidance for brighter future"}
          </Typography>
          <Divider
            variant="fullWidth"
            sx={{ border: "2px solid #FF6525", width: "96px" }}
          />
          <Typography
            sx={{
              color: "black",
              letterSpacing: "0px",
              padding: isMobile ? "10px 0px" : "40px 0px",
              width: "310px",
            }}
          >
            {"We make technology work for you"}
          </Typography>
          <Button
            sx={{
              marginTop: "22px",
              padding: "12px 20px",
              background:
                "transparent linear-gradient(105deg, #004DD8 0%, #41BEEA 100%) 0% 0% no-repeat padding-box",
              boxShadow: "0px 7px 23px #0000001C",
            }}
          >
            <Link
              sx={{ textDecoration: "none", fontWeight: "600", color: "white" }}
              href="/contact"
            >
              {"Contact us!"}
            </Link>
          </Button>
        </Box>
      </IntroBanner>

      <Box
        sx={{
          paddingLeft: "0px",
          paddingRight: "0px",
          margin: "0px",
          width: "100%",
        }}
      >
        <Box sx={styles.fullScreenBanner1}>
          <Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                padding: "20px 0px 20px 0px",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#848484",
                  letterSpacing: "5.04px",
                }}
              >
                {"SERVICES"}
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "34px",
                  color: "#004DD8",
                }}
              >
                {"Explore our varied areas of expertise"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                columnGap: "50px",
                rowGap: "24px",
              }}
            >
              {ourServices.map((ele, index) => (
                <Box
                  component={"a"}
                  href={`/detail/${ele.id.toLowerCase()}`}
                  sx={{
                    display: "flex",
                    background: "white",
                    boxShadow: "0px 7px 23px #0000001C",
                    borderRadius: "5px",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Avatar
                      sx={{
                        height: "60px",
                        width: "60px",
                        padding: "5px",
                        boxShadow: "0px 7px 23px #0000001C",
                        ".MuiAvatar-img": { height: "auto", width: "auto" },
                      }}
                      alt=""
                      src={require(`../assets/${ele.logo}.png`)}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#004DD8",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {ele.title}
                    </Typography>
                    <Typography sx={{ color: "#606060", fontSize: "14px" }}>
                      {ele.desciption}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Container>
        </Box>

        {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '230px', background: 'linear-gradient(106deg, #0659DA 20%, #3EB9E9 80%) 0% 0% no-repeat padding-box', padding: '40px 0px' }}>
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', paddingBottom: '20px' }}>
            <Box sx={{
              borderLeft: '#FF6525 2px solid',
              width: '10px'
            }} />
            <Typography sx={{ textAlign: 'left', fontWeight: '500', fontSize: '24px', color: 'white', }}>
              {'Our Recent Client'}
            </Typography>
          </Box>
          <Typography sx={{ textAlign: 'left', fontWeight: '300', fontSize: '18px', color: 'white', width: '450px' }}>
            {'From early stage start up to renowed brands, we have plenty of stories to share'}
          </Typography>
          <Box sx={{ textAlign: 'center', padding: '20px 0px' }}>

          </Box>
        </Container>
      </Box> */}

        <Box
          sx={{
            width: "100%",
            height: "100%",
            background:
              " transparent linear-gradient(104deg, #004DD8 0%, #3EB9E9 100%) 0% 0% no-repeat padding-box",
            padding: "60px 0px",
          }}
        >
          <Container>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "54px",
                }}
              >
                <Typography sx={{ color: "#FFFFFF", fontSize: "34px" }}>
                  {"We're Good with Numbers"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr 1fr",
                  }}
                >
                  {companyOutput.map((ele, idx) => (
                    <Box
                      key={`${ele.title}-${idx}`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: isTablet ? "20px" : "20px 75px",
                        borderRight:
                          isMobile || idx === companyOutput.length - 1
                            ? "none"
                            : "1px solid white",
                        borderBottom:
                          isMobile && idx !== companyOutput.length - 1
                            ? "1px solid white"
                            : "none",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontSize: "73px",
                          textAlign: "center",
                        }}
                      >
                        {ele.value}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontSize: "18px",
                          textAlign: "center",
                        }}
                      >
                        {ele.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box sx={styles.testimonialWrapper}>
          <Container>
            <Typography
              sx={{
                textAlign: "center",
                color: "#848484",
                fontSize: "14px",
                letterSpacing: "5.04px;",
              }}
            >
              {"TESTIMONINALS"}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                color: "#004DD8",
                fontSize: "34px",
                fontWeight: "600",
              }}
            >
              {`What our clients say about us.`}
            </Typography>
          </Container>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              padding: "20px",
              transition: "transform 0.4s",
              animation: "boxmove 15s linear infinite",
              ":hover": { animationPlayState: "paused" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                padding: "10px",
                perspective: "10px",
                zIndex: "8",
              }}
              className="lisr-wrapper"
            >
              {testimonialList.map((ele, idx) => (
                <Box
                  key={`${ele.designation}-${idx}`}
                  sx={{
                    width: "100%",
                    height: "320px",
                    display: "flex",
                    flexDirection: "column",
                    background: "white",
                    padding: "40px 18px 40px 18px",
                    boxShadow: "0px 7px 23px #0000001C",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      width: "250px",
                      fontSize: "14px",
                      color: "#606060",
                      textAlign: "left",
                      paddingBottom: "30px",
                      height: "228px",
                    }}
                  >
                    {ele.review}
                  </Typography>
                  <Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Divider
                        variant="fullWidth"
                        sx={{ border: "2px solid #FF6525", width: "96px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          boxShadow: "0px 7px 23px #0000001C",
                          height: "36px",
                          width: "36px",
                        }}
                        alt=""
                        src={""}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#004DD8",
                            textAlign: "left",
                          }}
                        >
                          {ele.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: "#000000",
                            textAlign: "left",
                          }}
                        >{`${ele.designation}, ${ele.currentCompany}`}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "230px",
            background:
              "linear-gradient(106deg, #0659DA 20%, #3EB9E9 80%) 0% 0% no-repeat padding-box",
            padding: "40px 0px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "15px",
              color: "white",
            }}
          >
            {"Got an idea?"}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "28px",
              color: "white",
            }}
          >
            {"Let's craft brilliance together!"}
          </Typography>
          <Box sx={{ textAlign: "center", padding: "20px 0px" }}>
            <Button
              sx={{
                color: "#004DD8",
                background: "white",
                ":hover": { background: "white", outline: "2px solid #3EB9E9" },
              }}
              size="medium"
            >
              <Link
                href="/contact"
                sx={{
                  textAlign: "center",
                  fontWeight: "900",
                  fontSize: "1.8em",
                  textDecoration: "none",
                }}
              >
                {"Join us now!"}
              </Link>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
