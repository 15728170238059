import { Accordion, Container, Modal, Typography, AccordionDetails, AccordionSummary, Button, TextField, Snackbar, Alert, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import LinkIcon from '@mui/icons-material/Link';
import { deleteJobPost, getJobPost, updateJobPost } from '../../utils/admin';
import draftjsToHtml from 'draftjs-to-html';
import Loader from '../../components/Loader';
import { copyToClipboard } from '../../utils/common';
const ViewJobs = () => {
    // const rows = [
    //     {
    //         id: 1, title: 'Html/Css', experience: 2, location: "abc", description: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse", jobDescription: [{
    //             title: 'text',
    //             content: '',
    //         }]
    //     },
    //     { id: 2, title: 'React Js', experience: 2, location: "abc", description: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse", jobDescription: null },
    //     { id: 3, title: 'Node Js', experience: 2, location: "abc", description: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse", jobDescription: null },
    //     { id: 4, title: 'mongoDb', experience: 2, location: "abc", description: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse", jobDescription: null },
    //     { id: 5, title: 'Api', experience: 2, location: "abc", description: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse", jobDescription: null },
    //     { id: 6, title: 'Tester', experience: 2, location: "abc", description: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse", jobDescription: null },
    // ];

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [message, setMessage] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedJobDescription, setselectedJobDescription] = useState()
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // const [jobDescriptions, setjobDescriptions] = useState([]);
    const [jobPost, setJobPost] = useState([]);
    const jdFormData = React.useRef({});
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 40,
        p: 4,
        '.toolbar-class': {
            padding: '10px',
            position: 'sticky',
            top: '0',
            'zIndex': '100'
        }
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 200, editable: false, },
        {
            field: 'title',
            headerName: 'Title',
            width: 150,
            editable: true,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'experience',
            headerName: 'Experience',
            width: 150,
            type: 'number',
            editable: true,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'location',
            headerName: 'Location',
            width: 150,
            editable: true,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 150,
            editable: true,
            headerClassName: 'center-aligned-header',
        },

        {
            field: 'shareLink',
            headerName: 'ShareLink',
            type: 'link',
            width: 150,
            renderCell: (cellValues) => {
                const onClickCopy = async () => {
                    copyToClipboard(`https://${window.location.host}/career/${cellValues?.row?.id || ''}`)
                    //    setIsError(true);
                    setIsSnackBarOpen(true);
                    setMessage("Link copied to clipboard");
                }
                return <Button onClick={onClickCopy}><LinkIcon /></Button>;
            },
            editable: false,
            headerClassName: 'center-aligned-header',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            getActions: (cellValues) => {
                return [
                    <GridActionsCellItem
                        icon={<WysiwygIcon />}
                        label="View"
                        onClick={() => {
                            console.log(cellValues?.row)
                            setselectedJobDescription(cellValues?.row?.jobDescription)
                            // console.log('fghj',cellValues?.row?.jobDescription)
                            jdFormData.current = cellValues?.row ? { ...cellValues?.row } : {}
                            setEditorState(getEditorState(cellValues?.row.jobDescription[0].content))
                            handleOpen()
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={async (e) => {
                            e.preventDefault()
                            setIsLoading(true)
                            if (cellValues?.row?.id) {
                                try {
                                    await deleteJobPost(cellValues?.row?.id);
                                    // console.log(data)
                                    window.location.reload()
                                    setIsLoading(false)
                                } catch (error) {
                                    // console.log(error)
                                    setIsLoading(false)
                                }
                            }

                        }}
                    />
                ];
            },
            headerClassName: 'center-aligned-header',
        },
    ];

    const getUpdatedJobPosts = async () => {
        setIsLoading(true)
        const JobData = await getJobPost()
        if (JobData?.length) {
            setJobPost(JobData)
        }
        setIsLoading(false)
    }

    const handleEditorStateChange = (newEditorState, id) => {
        setEditorState(newEditorState);
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlString = draftjsToHtml(rawContentState);
        const selectedJd = selectedJobDescription.map(ele => {
            if (ele.id === id) {
                return {
                    ...ele,
                    content: htmlString,

                }
            } else {
                return {
                    ...ele
                }
            }
        })
        setselectedJobDescription(selectedJd);
    };

    const handleSubmit = async () => {

        try {
            const payload = { ...jdFormData.current, jobDescription: selectedJobDescription };
            console.log('payload', payload)
            setIsLoading(true);
            if (payload?.jobDescription && payload.title && payload.experience && payload.location) {
                await updateJobPost(payload);
            }
            else {
                console.log('please provide required fields');
            }
            setIsLoading(false);
            // jdFormData.current = null;
            // handleEditorStateChange(EditorState.createEmpty());
            window.location.reload()
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            jdFormData.current = null;
            handleEditorStateChange(EditorState.createEmpty());
        }


    }

    const getEditorState = (content) => {
        const blocksFromHTML = convertFromHTML(content);
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        const newEditorState = EditorState.createWithContent(contentState);
        return newEditorState

    }

    const updateJobDescriotion = (id, updatedValues) => {
        const updatedData = selectedJobDescription.map(item => {
            if (item.id === id) {
                return { ...item, ...updatedValues };
            }
            return item;
        });

        setselectedJobDescription(updatedData);
    };

    const jdFormHandleOnchange = (name, value, id) => {
        const payload = jdFormData.current;
        const selectedJd = selectedJobDescription.find(ele => ele.id === id);

        if (name && value) {
            payload[name] = value;
        }

        if (selectedJd && name && selectedJd[name]) {
            selectedJd[name] = value
            updateJobDescriotion(id, selectedJd);
        }

        jdFormData.current = payload;
    }

    useEffect(() => {
        getUpdatedJobPosts()
    }, [])

    if (isLoading) return <Loader />

    return (
        <Container sx={{ minHeight: '85vh' }}>
            <Typography sx={{ marginLeft: '500px', marginY: '30px' }} variant="h6" gutterBottom>
                View Jobs
            </Typography>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={jobPost}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    // checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography sx={{ marginY: "10px" }} id="modal-modal-title" variant="h6" component="h2">
                        Job Description
                    </Typography>

                    <Grid item xs={12}>
                        {selectedJobDescription?.length ? selectedJobDescription.map((ele) => (<Accordion sx={{ height: 'max-content', padding: '20px 12px' }}>
                            <AccordionSummary
                                expandIcon={<ExpandCircleDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >

                                <Typography variant="h6" component="h6">
                                    {ele.title}
                                </Typography>

                            </AccordionSummary>
                            <AccordionDetails>
                                {/* <Typography variant="h6" component="h6">
                                    Title
                                </Typography> */}
                                <Grid item xl={12} style={{ height: "max-content" }}>
                                    <TextField
                                        required
                                        id="title"
                                        name="title"
                                        label="Title"
                                        fullWidth
                                        value={ele.title}
                                        onChange={(e) => jdFormHandleOnchange(e.currentTarget.name, e.currentTarget.value, ele?.id)}
                                        autoComplete="title"
                                        variant="standard"

                                    />
                                </Grid>
                                <Typography variant="h6" component="h6">
                                    Content
                                </Typography>
                                <Grid item xl={12} style={{
                                    height: 'auto', maxHeight: '300px',
                                    overflowY: 'auto',
                                }}>
                                    <Editor
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        editorState={editorState}
                                        onEditorStateChange={(e) => handleEditorStateChange(e, ele?.id)}
                                        toolbar={{
                                            options: [
                                                "inline",
                                                "blockType",
                                                "list",
                                                "link",
                                                "emoji",
                                                "image",
                                                "remove",
                                                "history",
                                            ],
                                            inline: {
                                                options: [
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strikethrough",
                                                ],
                                            },
                                        }}
                                    />
                                </Grid>

                            </AccordionDetails>
                        </Accordion>)) : ''
                        }

                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Button variant="contained" onClick={handleSubmit}>Save</Button>
                    </Grid>
                </Box>
            </Modal>

            <Snackbar open={isSnackBarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} elevation={6} severity={'success'} sx={{ width: '100%', }}>
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default ViewJobs